import React from "react";

import {
  formatNumber,
  formatBytes,
  formatMs,
  formatTimestampShortest,
} from "utils/format";

import moment from "moment";
import ExplainFingerprint from "components/ExplainFingerprint";
import { ComparablePlanType } from "components/WithExplainPlan";

type Props = {
  planA: ComparablePlanType;
  planB: ComparablePlanType;
};

const ExplainCompareSummary: React.FunctionComponent<Props> = ({
  planA,
  planB,
}) => {
  return (
    <div>
      <PlanSummary plan={planA} side="A" />
      <PlanSummary plan={planB} side="B" />
    </div>
  );
};

function PlanSummary({
  plan,
  side,
}: {
  plan: ComparablePlanType;
  side: "A" | "B";
}) {
  return (
    <div>
      <h3 className="leading-tight text-lg font-medium text-[#333]">
        Plan {side}: {plan.label}
      </h3>
      <div className="grid gap-4 grid-cols-3">
        <SummaryItem label="Seen At">
          {plan.seenAt != null
            ? formatTimestampShortest(moment.unix(plan.seenAt))
            : "-"}
        </SummaryItem>
        <SummaryItem label="Total Est. Cost">
          {plan.totCost != null ? formatNumber(plan.totCost) : "N/A"}
        </SummaryItem>
        <SummaryItem label="Runtime">
          {plan.runtime != null ? formatMs(plan.runtime) : "-"}
        </SummaryItem>
        <SummaryItem label="Plan Fingerprint">
          <ExplainFingerprint fingerprint={plan.fingerprint} />
        </SummaryItem>
        <SummaryItem label="Read From Disk">
          {plan.ioBytes != null ? formatBytes(plan.ioBytes) : "-"}
        </SummaryItem>
        <SummaryItem label="I/O Read Time">
          {plan.ioMs != null ? formatMs(plan.ioMs) : "-"}
        </SummaryItem>
      </div>
    </div>
  );
}

function SummaryItem({
  children,
  label,
}: {
  children: React.ReactNode;
  label: string;
}) {
  return (
    <div className="flex flex-col justify-between">
      <span className="block mb-0.5 text-xs font-bold text-[#666679]">
        {label}
      </span>
      <div>{children}</div>
    </div>
  );
}

export default ExplainCompareSummary;
