import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import ModalContainer from "components/ModalContainer";
import { ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType } from "../ExplainWorkbook/types/ExplainWorkbookDetails";

import CREATE_MUTATION from "../SetUpSamples/Mutation.create.graphql";
import {
  CreateExplainParameterSets,
  CreateExplainParameterSetsVariables,
} from "../SetUpSamples/types/CreateExplainParameterSets";
import {
  aliasParamMapToString,
  convertParamValue,
  convertToParamSetType,
  ParamSetType,
  formatQueryTextWithParamValues,
  validateParamSet,
} from "../ExplainWorkbook/util";
import PillButtonBar from "components/PillButtonBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import Button from "components/Button";
import CopyToClipboard from "components/CopyToClipboard";
import Grid from "components/Grid";
import SQL from "components/SQL";
import { QueryTextArea } from "../ReviewQuery";

const CreateParameterSetPanel = ({
  onDismiss,
  workbook,
}: {
  onDismiss: () => void;
  workbook: ExplainWorkbookType;
}) => {
  const baselineQuery = workbook.baselineQuery;
  const { databaseId } = useParams();
  const [queryText, setQueryText] = useState(
    workbook.baselineQuery.queryTextWithAlias,
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [customParamsQuery, setCustomParamsQuery] = useState("");

  const [paramSet, setParamSet] = useState<ParamSetType>({});
  const [section, setSection] = useState<"from query" | "manual" | "copy from">(
    "from query",
  );

  const [createExplainParameterSets] = useMutation<
    CreateExplainParameterSets,
    CreateExplainParameterSetsVariables
  >(CREATE_MUTATION);

  const handleAddParamsFromQuery = () => {
    if (customParamsQuery == "") {
      setErrorMessage("Query is required");
      return;
    }
    createExplainParameterSets({
      variables: {
        databaseId,
        explainQueryId: baselineQuery.id,
        queryText: customParamsQuery,
      },
      onCompleted: () => {
        setErrorMessage("");
        setCustomParamsQuery("");
        onDismiss();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleAddParamsManual = () => {
    const validateError = validateParamSet(
      paramSet,
      Object.keys(baselineQuery.paramRefAliasMap).length,
    );
    if (validateError) {
      setErrorMessage(validateError);
      return;
    }
    const newSet = Object.entries(paramSet).reduce((accum, [ref, set]) => {
      accum[ref] = {
        value: convertParamValue(set),
        type: set.type,
      };
      return accum;
    }, {} as ParamSetType);
    createExplainParameterSets({
      variables: {
        databaseId,
        explainQueryId: baselineQuery.id,
        parameterSet: newSet,
      },
      onCompleted: () => {
        setErrorMessage("");
        onDismiss();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handlePreviewParameters = () => {
    const preview = formatQueryTextWithParamValues(
      workbook.baselineQuery.queryTextWithAlias,
      paramSet,
    );
    setQueryText(preview);
  };

  const handleParamValueChange = (alias: string, value: string) => {
    const prevParam = paramSet[alias];
    const newSet = { ...paramSet };
    if (value === "" && !prevParam?.type && !prevParam?.isNull) {
      // remove from the set when the value becomes an empty string without type or flagged as null
      delete newSet[alias];
      setParamSet(newSet);
      return;
    }
    newSet[alias] = {
      value: value,
      type: prevParam?.type,
      isNull: prevParam?.isNull,
    };
    setParamSet(newSet);
  };
  const handleParamNullChange = (alias: string, isNull: boolean) => {
    const prevParam = paramSet[alias];
    const newSet = { ...paramSet };
    // when null is checked but there is an existing value, empty it
    newSet[alias] = {
      value: isNull ? undefined : prevParam?.value,
      type: prevParam?.type,
      isNull: isNull,
    };
    setParamSet(newSet);
  };

  const inputFields = Object.entries(baselineQuery.paramRefAliasMap).flatMap(
    ([ref, alias]) => {
      const aliasStr = alias as string;
      return [
        <div key={`label-${ref}`}>{aliasStr}</div>,
        <input
          key={`input-${ref}`}
          className="bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5 disabled:bg-[#eee]"
          type="text"
          value={paramSet[aliasStr]?.value ?? ""}
          disabled={paramSet[aliasStr]?.isNull}
          onChange={(e) => handleParamValueChange(aliasStr, e.target.value)}
        />,
        <input
          key={`null-${ref}`}
          type="checkbox"
          checked={paramSet[aliasStr]?.isNull || false}
          onChange={(e) => handleParamNullChange(aliasStr, e.target.checked)}
        />,
        <div key={`type-${ref}`}>
          {paramSet[aliasStr]?.type ?? "Auto-detect"}
        </div>,
      ];
    },
  );

  return (
    <ModalContainer title="New Sample" layout="centered" onClose={onDismiss}>
      <div className="relative">
        <QueryTextArea
          queryText={queryText}
          className="max-h-[100px] overflow-y-scroll py-4 mb-4"
        />
        <CopyToClipboard
          className="absolute top-1 right-2 text-[12px] text-[#999]"
          label="copy"
          content={queryText}
        />
      </div>
      <div className="mb-4">
        <PillButtonBar
          opts={[
            { value: "from query", label: "Extract params from Query" },
            { value: "manual", label: "Specify manually" },
            { value: "copy from", label: "Copy from existing params" },
          ]}
          selected={section}
          onChange={(value) => {
            setErrorMessage("");
            setQueryText(workbook.baselineQuery.queryTextWithAlias);
            setSection(value);
          }}
        />
      </div>
      {section === "from query" ? (
        <div>
          <textarea
            className="bg-white rounded border border-gray-300 box-border w-full leading-5 px-2 py-1.5 disabled:bg-[#eee]"
            placeholder="Paste query with parameter values..."
            value={customParamsQuery}
            rows={10}
            onChange={(e) => setCustomParamsQuery(e.target.value)}
          />
        </div>
      ) : section === "manual" ? (
        <div>
          <div className="grid grid-cols-[min-content_1fr_40px_200px] gap-2 mb-4 items-center font-medium">
            <div>Name</div>
            <div>Value</div>
            <div>NULL</div>
            <div>Param Type</div>
            {Object.values(inputFields)}
          </div>
          <Button bare onClick={handlePreviewParameters}>
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Preview Parameters
          </Button>
        </div>
      ) : (
        <Grid
          className="grid-cols-[1fr_160px] mb-4"
          data={workbook.aliasParamMapList}
          noRowsText="No custom parameters"
          columns={[
            {
              field: "parameters",
              header: "Parameter Sets",
              renderer: function ParametersCell({ fieldData }) {
                return <SQL sql={aliasParamMapToString(fieldData)} />;
              },
              className: "whitespace-normal",
              disableSort: true,
            },
            {
              field: "id",
              header: "",
              renderer: function UseParamCell({ fieldData }) {
                return (
                  <Button
                    bare
                    onClick={() => {
                      const copyFromAliasParamMap =
                        workbook.aliasParamMapList.find(
                          (ap) => ap.id === fieldData,
                        );
                      const copyFromParams = copyFromAliasParamMap
                        ? convertToParamSetType(
                            copyFromAliasParamMap.parameters,
                          )
                        : {};
                      setParamSet(copyFromParams);
                      setSection("manual");
                    }}
                  >
                    Copy this param
                  </Button>
                );
              },
              className: "text-right",
              disableSort: true,
            },
          ]}
        />
      )}
      <div className="mt-2 text-[#FF0000]">{errorMessage}</div>
      <div className="text-right mt-4">
        <button className="btn !px-10" onClick={onDismiss}>
          Cancel
        </button>
        <button
          className="btn btn-success"
          onClick={
            section === "from query"
              ? handleAddParamsFromQuery
              : handleAddParamsManual
          }
        >
          Add Custom Parameter
        </button>
      </div>
    </ModalContainer>
  );
};

export default CreateParameterSetPanel;
