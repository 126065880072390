import React from "react";
import { useQuery } from "@apollo/client";

import Panel from "components/Panel";

import { formatBytes } from "utils/format";

import {
  AreaSeries,
  LineSeries,
  ThresholdSeries,
} from "components/Graph/Series";
import { Datum, SeriesConfig } from "components/Graph/util";
import { Data } from "components/Graph/util";
import { useDateRange } from "components/WithDateRange";
import CopyCodeBlock from "components/CopyCodeBlock";

import { Memory as MemoryType, MemoryVariables } from "./types/Memory";
import {
  BufferCache as BufferCacheType,
  BufferCacheVariables,
} from "./types/BufferCache";
import QUERY from "./Query.graphql";
import QUERY_BUFFER_CACHE from "./Query.bufferCache.graphql";
import GraphSection from "components/Graph/GraphSection";
import DateRangeGraph from "components/Graph/DateRangeGraph";
import Loading from "components/Loading";
import PanelSection from "components/PanelSection";

type Props = {
  serverId: string;
  systemType: string;
  amazonRdsEnhanced: boolean;
};

const Memory = ({ serverId, systemType, amazonRdsEnhanced }: Props) => {
  return (
    <>
      {supportsMemoryMetrics(systemType, amazonRdsEnhanced) ? (
        <SystemMemory serverId={serverId} />
      ) : (
        <Panel title="Memory">
          <PanelSection>
            Memory system metrics are not supported for your system.
          </PanelSection>
        </Panel>
      )}
      <BufferCache serverId={serverId} />
    </>
  );
};

const SystemMemory = ({ serverId }: { serverId: string }) => {
  const [range] = useDateRange();
  const { from: newStartTs, to: newEndTs } = range;

  const { data, loading, error } = useQuery<MemoryType, MemoryVariables>(
    QUERY,
    {
      variables: {
        serverId,
        startTs: newStartTs.unix(),
        endTs: newEndTs.unix(),
      },
    },
  );

  const noData = !loading && !error && data.getSystemStats == null;

  const secondaryTitle = (
    <span>
      <strong>Note:</strong> Process Memory includes Postgres{" "}
      <code>shared_buffers</code>
    </span>
  );

  return (
    <Panel title="Memory" secondaryTitle={secondaryTitle}>
      <GraphSection noData={noData} loading={loading} error={error}>
        <DateRangeGraph
          data={data?.getSystemStats as unknown as Data}
          axes={{
            left: {
              format: formatBytes,
            },
          }}
          series={[
            {
              key: "memoryTotal",
              type: LineSeries,
              label: "Total Memory",
            },
            {
              key: "memoryProcess",
              type: AreaSeries,
              label: "Process Memory",
            },
            {
              key: "memoryPageCache",
              type: AreaSeries,
              label: "OS Page Cache",
            },
            { key: "memoryBuffers", type: AreaSeries, label: "OS Buffers" },
            {
              key: "memoryFree",
              type: AreaSeries,
              label: "Free Memory",
            },
            { key: "memorySwap", type: AreaSeries, label: "Swap In Use" },
          ]}
        />
      </GraphSection>
    </Panel>
  );
};

const BufferCache = ({ serverId }: { serverId: string }) => {
  const [{ from, to }] = useDateRange();
  const {
    data: results,
    loading,
    error,
  } = useQuery<BufferCacheType, BufferCacheVariables>(QUERY_BUFFER_CACHE, {
    variables: {
      serverId,
      startTs: from.unix(),
      endTs: to.unix(),
    },
  });
  const { stats, sharedBuffers } = results?.getBufferCacheUsage || {};

  const graphs = [];
  let data: Data = {};
  let series: SeriesConfig[] = [];
  for (let i = 0; i < 20 && i < stats?.length; i++) {
    if (series.length == 10) {
      graphs.push([data, series]);
      data = {};
      series = [];
    }
    const stat = stats[i];
    data[stat.id] = stat.usage as unknown as Datum[];
    series.push({
      key: stat.id,
      label: stat.name,
      type: AreaSeries,
    });
  }
  if (series.length) {
    graphs.push([data, series]);
  }
  for (const graph of graphs) {
    (graph[0] as Data).max = stats[0].usage.map((u) => [u[0], sharedBuffers]);
    (graph[1] as SeriesConfig[]).push({
      key: "max",
      label: "shared_buffers max",
      color: "grey",
      type: ThresholdSeries,
    });
  }

  return (
    <Panel title="Postgres Buffer Cache">
      {loading || error ? (
        <Loading error={!!error} />
      ) : graphs.length ? (
        graphs.map(([data, series], i) => (
          <GraphSection key={i} loading={loading} error={error}>
            <h4>{i == 0 ? "Top 10 Tables" : "Top 10 Outliers"}</h4>
            <DateRangeGraph
              data={data as Data}
              axes={{
                left: {
                  format: formatBytes,
                },
              }}
              series={series as SeriesConfig[]}
              height={270}
            />
          </GraphSection>
        ))
      ) : (
        <PanelSection>
          <p>
            Collector version 0.63.0 introduced per-table buffer cache tracking.
            Please{" "}
            <a
              href="https://pganalyze.com/docs/collector/upgrading"
              target="_blank"
            >
              upgrade the collector
            </a>{" "}
            and run:
          </p>
          <CopyCodeBlock>
            CREATE EXTENSION IF NOT EXISTS pg_buffercache WITH SCHEMA public;
          </CopyCodeBlock>
          <p>
            If you don't see stats after 20 minutes, check the collector logs
            for error messages.
          </p>
          <p>
            If the collector runs into permissions issues when querying
            pg_buffercache, run:
          </p>
          <CopyCodeBlock>
            {`GRANT USAGE ON SCHEMA public TO pganalyze;\nGRANT SELECT ON pg_buffercache TO pganalyze;\nGRANT EXECUTE ON FUNCTION pg_buffercache_pages TO pganalyze;`}
          </CopyCodeBlock>
        </PanelSection>
      )}
    </Panel>
  );
};

function supportsMemoryMetrics(
  systemType: string,
  amazonRdsEnhanced: boolean,
): boolean {
  return (
    ["physical", "crunchy_bridge", "heroku", "tembo"].includes(systemType) ||
    amazonRdsEnhanced
  );
}

export default Memory;
