import React, { useMemo, useState } from "react";

import classNames from "classnames";

import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import ExplainDetails from "components/ExplainDetails";
import ExplainSidebar from "components/ExplainSidebar";
import ExpandableSQL from "components/ExpandableSQL";
import WithNodeSelection from "components/Explain/WithNodeSelection";
import PillButtonBar from "components/PillButtonBar";
import ExplainComparison from "./ExplainComparison";
import ExplainViewSource from "./ExplainViewSource";
import WithExplainDisplayOptions from "./WithExplainDisplayOptions";
import type { ExplainPlanType, QuerySampleType } from "components/Explain/util";

import styles from "./style.module.scss";
import WithExplainPlan, {
  ComparablePlanType,
} from "components/WithExplainPlan";
import { AnnotatedPlan } from "types/explain";
import ExplainCompareSidebar from "components/ExplainCompareSidebar";
import { useFeature } from "components/OrganizationFeatures";
import PanelParamSection from "./PanelParamSection";

type Props = {
  explain: ExplainPlanType;
  databaseId: string;
  blockSize: number;
  hideComparison?: boolean;
};
type SectionType = "viz" | "text" | "json" | "comparison";

const ExplainPanel: React.FunctionComponent<Props> = ({
  explain,
  databaseId,
  blockSize,
  hideComparison,
}) => {
  const [section, setSection] = useState<SectionType>("viz");
  const hasExplainCompareFeature = useFeature("explainCompare");

  const plan = useMemo<AnnotatedPlan>(() => {
    return JSON.parse(explain.annotatedJson);
  }, [explain.annotatedJson]);

  const pillButtonOptions = [
    { value: "viz", label: "Node Tree" },
    { value: "text", label: "Text" },
    { value: "json", label: "JSON" },
  ];
  if (!hideComparison) {
    pillButtonOptions.push({ value: "comparison", label: "Compare Plans" });
  }

  const initialPlan: ComparablePlanType = {
    id: explain.id,
    seenAt: explain.seenAt,
    label: "",
    fingerprint: explain.fingerprint,
    ioBytes: explain.totalSharedBlksRead * blockSize,
    ioMs: explain.totalBlkReadTime,
    runtime: explain.querySample?.runtimeMs,
    totCost: explain.totalCost,
    plan,
  };

  return (
    <div className="px-[20px] flex-grow h-min overflow-auto">
      <div className="h-full flex">
        <WithExplainPlan initialPlan={initialPlan}>
          <WithNodeSelection>
            <WithExplainDisplayOptions>
              <div className="flex-grow relative overflow-y-scroll pl-1 -ml-1 pr-4">
                <div
                  className={classNames(
                    "py-4 mb-1 flex gap-5 sticky top-0 z-50 px-1 -mx-1",
                    styles.buttonBar,
                  )}
                >
                  <PillButtonBar
                    opts={pillButtonOptions}
                    selected={section}
                    onChange={(value) => setSection(value as SectionType)}
                  />
                </div>
                <QueryPanel querySample={explain.querySample} />
                {section === "comparison" ? (
                  <ExplainComparison
                    databaseId={databaseId}
                    blockSize={blockSize}
                    plan={plan}
                    explain={explain}
                  />
                ) : section === "json" || section === "text" ? (
                  <div className="mt-8">
                    <ExplainViewSource
                      format={section}
                      annotatedPlan={plan}
                      textPlan={explain.outputText}
                    />
                  </div>
                ) : section === "viz" ? (
                  <ExplainDetails plan={plan} databaseId={databaseId} />
                ) : null}
              </div>
              {/* N.B.: pb is to clear the Get Help button in the lower right corner */}
              {(section !== "comparison" || hasExplainCompareFeature) && (
                <div className="p-4 pb-[80px] border-l w-[480px] -mr-4 shrink-0 overflow-y-scroll">
                  {section === "comparison" ? (
                    <ExplainCompareSidebar
                      blockSize={blockSize}
                      databaseId={databaseId}
                    />
                  ) : (
                    <ExplainSidebar
                      explain={explain}
                      blockSize={blockSize}
                      plan={plan}
                      databaseId={databaseId}
                      summaryOnly={section === "json" || section === "text"}
                    />
                  )}
                </div>
              )}
            </WithExplainDisplayOptions>
          </WithNodeSelection>
        </WithExplainPlan>
      </div>
    </div>
  );
};

const QueryPanel = ({ querySample }: { querySample: QuerySampleType }) => {
  if (!querySample) {
    return null;
  }
  const parameters = querySample.parameters;
  return (
    <Panel title="SQL Statement">
      <PanelSection>
        <ExpandableSQL sql={querySample.queryText} />
      </PanelSection>
      <PanelParamSection parameters={parameters} />
    </Panel>
  );
};

export default ExplainPanel;
