import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./style.module.scss";

type LinkProps = {
  to: string;
  children: React.ReactNode;
  end?: boolean;
};

export class PageNavLink extends React.Component<LinkProps> {
  render() {
    return (
      <NavLink
        to={this.props.to}
        className={({ isActive }) => (isActive ? styles.active : undefined)}
        end={this.props.end === undefined || this.props.end}
      >
        {this.props.children}
      </NavLink>
    );
  }
}

type Props = {
  children: React.ReactNode;
};

class PageSecondaryNavigation extends React.Component<Props> {
  render() {
    return <div className={styles.container}>{this.props.children}</div>;
  }
}

export default PageSecondaryNavigation;
