import React from "react";

import PanelSection from "components/PanelSection";
import { elide } from "utils/string";
import classNames from "classnames";

function PanelParamSection({
  parameters,
}: {
  parameters: (string | null)[] | null;
}) {
  if (!parameters || parameters.length == 0) {
    return null;
  }

  const displayedParams = parameters.filter((_param, i) => i < 50);

  const classes = classNames(
    "whitespace-pre-wrap border-none m-0 p-0 bg-transparent",
    CSS.supports("display: -webkit-box")
      ? "line-clamp-3"
      : "overflow-hidden max-h-14",
  );

  return (
    <PanelSection>
      <pre className={classes}>
        {displayedParams.map((p, i) => {
          return (
            <>
              <Param n={i + 1} value={p} />
              {i < displayedParams.length - 1 && ", "}
            </>
          );
        })}
      </pre>
    </PanelSection>
  );
}

function Param({ value, n }: { value: string | null; n: number }) {
  const title = paramStr(value);
  return (
    <span title={title}>
      ${n} = {paramStr(value, 20)}
    </span>
  );
}

function paramStr(param: string | null, maxLen: number = Infinity) {
  return param === null ? "NULL" : `'${elide(param, maxLen)}'`;
}

export default PanelParamSection;
