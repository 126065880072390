import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ApiKeys from "components/ApiKeys";
import BackendDetails from "components/BackendDetails";
import BackendList from "components/BackendList";
import Billing from "components/Billing";
import CheckUp from "components/CheckUp";
import CheckUpDetails from "components/CheckUpDetails";
import Dashboard from "components/Dashboard";
import IndexAdvisorServerOverview from "components/IndexAdvisorServerOverview";
import IndexAdvisorOverview from "components/IndexAdvisorOverview";
import Loading from "components/Loading";
import LogInsights from "components/LogInsights";
import MemberDetails from "components/MemberDetails";
import MemberEdit from "components/MemberEdit";
import MemberList from "components/MemberList";
import MemberNew from "components/MemberNew";
import MemberAcceptInvite from "components/MemberAcceptInvite";
import RoleDetails from "components/RoleDetails";
import RoleEdit from "components/RoleEdit";
import RoleList from "components/RoleList";
import RoleNew from "components/RoleNew";
import SchemaFunctionDetails from "components/SchemaFunctionDetails";
import SchemaFunctionList from "components/SchemaFunctionList";
import SchemaIndexDetails from "components/SchemaIndexDetails";
import SchemaIndexList from "components/SchemaIndexList";
import SchemaTableDetails from "components/SchemaTableDetails";
import SchemaTableList from "components/SchemaTableList";
import SchemaViewList from "components/SchemaViewList";
import SchemaViewDetails from "components/SchemaViewDetails";
import ServerConnections from "components/ServerConnections";
import ServerDashboard from "components/ServerDashboard";
import ServerLocalCollectorNew from "components/ServerLocalCollectorNew";
import ServerQueryOverview from "components/ServerQueryOverview";
import ServerSchemaOverview from "components/ServerSchemaOverview";
import ServerSettings from "components/ServerSettings";
import ServerSetup from "components/ServerSetup";
import SnapshotDetails from "components/SnapshotDetails";
import SnapshotList from "components/SnapshotList";
import SystemDetails from "components/SystemDetails";
import NewOrganization from "components/NewOrganization";
import OrganizationOverview from "components/OrganizationOverview";
import OrganizationSettings from "components/OrganizationSettings";
import PostgresRoleDetails from "components/PostgresRoleDetails";
import PostgresSettingDetails from "components/PostgresSettingDetails";
import PostgresSettingList from "components/PostgresSettingList";
import QueryDetails from "components/QueryDetails";
import VacuumDetails from "components/VacuumDetails";
import ShowFlash from "components/ShowFlash";

import ServerInstallDocs from "components/ServerInstallDocs";

import PageLayout from "components/PageLayout";
import RootRedirect from "components/RootRedirect";
import { WithDateRange } from "components/WithDateRange";
import { retention } from "utils/limits";
import { WithRouteState } from "utils/routeState";
import { WithHelpOpenState } from "components/Help";
import OrganizationSetup from "components/OrganizationSetup";
import ServerSettingsList from "components/ServerSettingsList";
import ServerSetupLogInsightsDocs from "components/ServerSetupLogInsightsDocs";
import ServerSetupAutomatedExplainDocs from "components/ServerSetupAutomatedExplainDocs";
import IssueDetailPage from "components/IssueDetailPage";
import AlertConfig from "components/AlertConfig";
import OrganizationIntegrations from "components/OrganizationIntegrations";
import WithAppConfig from "components/WithAppConfig";
import WithCurrentOrganization, {
  useCurrentServer,
} from "components/WithCurrentOrganization";
import WithSubscriptionCheck from "components/WithSubscriptionCheck";
import WithSentry from "components/WithSentry";
import WithFlashes, { Flash, FlashLevel } from "components/WithFlashes";
import VacuumAdvisor from "components/VacuumAdvisor";
import QueryPerformance from "components/QueryPerformance";
import ExplainWorkbook from "components/ExplainWorkbookList/ExplainWorkbook";
import ExplainOverview from "components/ExplainOverview";
import WithLegacyStyles from "components/WithLegacyStyles";
import QueryTuning from "components/QueryTuning";
import { useFeature } from "components/OrganizationFeatures";
import ExplainVariant from "components/ExplainWorkbookList/ExplainVariant";
import ExplainResult from "components/ExplainWorkbookList/ExplainResult";
import ReviewQuery from "components/ExplainWorkbookList/ReviewQuery";
import RewriteQuery from "components/ExplainWorkbookList/RewriteQuery";

type LegacyContentType = Record<string, React.ReactElement> | React.ReactNode;

type Props = {
  legacyContent?: LegacyContentType;
};

const Page: React.FunctionComponent<
  Props & {
    flashes?: Array<{
      name: string;
      msg: string;
      persist?: boolean;
    }>;
  }
> = ({ flashes, ...otherProps }) => {
  const serverFlashes: Flash[] =
    flashes?.map((serverFlash) => {
      return {
        level: serverFlash.name as FlashLevel,
        escapedHtmlMsg: serverFlash.msg,
        persist: serverFlash.persist,
      };
    }) ?? [];
  return (
    <WithAppConfig>
      <BrowserRouter>
        <WithFlashes initialFlashes={serverFlashes}>
          <WithCurrentOrganization>
            <WithSentry>
              <WithRouteState>
                <WithDateRange initialHorizon={retention.default}>
                  <WithHelpOpenState>
                    <WithLegacyStyles>
                      <RoutePage {...otherProps} />
                    </WithLegacyStyles>
                  </WithHelpOpenState>
                </WithDateRange>
              </WithRouteState>
            </WithSentry>
          </WithCurrentOrganization>
        </WithFlashes>
      </BrowserRouter>
    </WithAppConfig>
  );
};

const LegacyContent: React.FunctionComponent<{
  contentKey: string;
  legacyContent?: LegacyContentType;
}> = ({ contentKey, legacyContent }) => {
  if (legacyContent && legacyContent[contentKey]) {
    return legacyContent[contentKey];
  } else {
    // This page requires content supplied by the server, so we need to reload
    // to get it after client-based navigation
    window.location.reload();
    return <Loading />;
  }
};

export const RoutePage: React.FunctionComponent<Props> = ({
  legacyContent,
}) => (
  <PageLayout content={<RoutePageContent legacyContent={legacyContent} />} />
);

const RoutePageContent: React.FunctionComponent<{
  legacyContent: LegacyContentType;
}> = ({ legacyContent }) => {
  const server = useCurrentServer(true);
  const hasExplainUpload = useFeature("explainUpload");
  const schemaProcessingDisabled = server?.schemaProcessingDisabled;
  return (
    <>
      {schemaProcessingDisabled && <SchemaProcessingDisabledFlash />}

      <Routes>
        <Route index element={<RootRedirect />} />
        <Route path="/organizations" element={<RootRedirect />} />
        <Route path="/servers" element={<RootRedirect />} />
        <Route path="/databases" element={<RootRedirect />} />
        <Route path="/organizations/new" element={<NewOrganization />} />
        <Route path="/organizations/:slug">
          <Route index element={<OrganizationOverview />} />
          <Route path="edit" element={<OrganizationSettings />} />
          <Route path="integrations" element={<OrganizationIntegrations />} />
          <Route path="api" element={<ApiKeys />} />
          <Route path="members" element={<MemberList />} />
          <Route path="members/new" element={<MemberNew />} />
          <Route path="members/:id" element={<MemberDetails />} />
          <Route path="members/:id/edit" element={<MemberEdit />} />
          <Route
            path="members/accept/:inviteToken"
            element={<MemberAcceptInvite />}
          />
          <Route path="roles" element={<RoleList />} />
          <Route path="roles/new" element={<RoleNew />} />
          <Route path="roles/:id" element={<RoleDetails />} />
          <Route path="roles/:id/edit" element={<RoleEdit />} />
          <Route path="setup" element={<OrganizationSetup />} />
          <Route path="subscription" element={<Billing tab="overview" />} />
          <Route
            path="subscription/compare"
            element={<Billing tab="compare" />}
          />
          <Route
            path="subscription/activate/:planId"
            element={<Billing tab="activate_plan" />}
          />
          <Route
            path="subscription/update_billing_details"
            element={<Billing tab="update_billing_details" />}
          />
          <Route
            path="subscription/update_credit_card"
            element={<Billing tab="update_credit_card" />}
          />
          <Route
            path="subscription/cancel"
            element={<Billing tab="cancel_plan" />}
          />
          <Route element={<WithSubscriptionCheck />}>
            <Route
              path="servers"
              element={<OrganizationOverview tab="servers" />}
            />
            <Route
              path="databases"
              element={<OrganizationOverview tab="databases" />}
            />
            <Route path="servers/new" element={<ServerLocalCollectorNew />} />
            <Route path="servers/settings" element={<ServerSettingsList />} />
            <Route path="servers/*" element={<ServerInstallDocs />} />
          </Route>
          <Route element={<WithSubscriptionCheck requireFeature="checkUp" />}>
            <Route path="alerts" element={<CheckUp tab="alerts" />} />
            <Route
              path="alerts/policies/:alertPolicyId"
              element={<CheckUp tab="alerts" />}
            />
            <Route path="checks" element={<CheckUp tab="checks" />} />
            <Route path="checks/:checkGroup/:checkName">
              <Route index element={<CheckUpDetails tab="triggered" />} />
              <Route
                path="acknowledged"
                element={<CheckUpDetails tab="acknowledged" />}
              />
              <Route
                path="resolved"
                element={<CheckUpDetails tab="resolved" />}
              />
              <Route
                path="configure"
                element={<CheckUpDetails tab="configure" />}
              />
            </Route>
          </Route>
        </Route>
        <Route path="/servers/:serverId">
          <Route element={<WithSubscriptionCheck />}>
            <Route index element={<ServerDashboard />} />
            <Route path="connections" element={<ServerConnections />} />
            <Route
              path="indexadvisor"
              element={<IndexAdvisorServerOverview />}
            />
            <Route path="roles" element={<SystemDetails tab="roles" />} />
            <Route path="roles/:id" element={<PostgresRoleDetails />} />
            <Route
              path="replication"
              element={<SystemDetails tab="replication" />}
            />
            <Route path="setup" element={<ServerSetup />} />
            <Route
              path="setup/log-insights/*"
              element={<ServerSetupLogInsightsDocs />}
            />
            <Route
              path="setup/explain/*"
              element={<ServerSetupAutomatedExplainDocs />}
            />
            <Route path="edit" element={<ServerSettings />} />
            <Route path="snapshots" element={<SnapshotList />} />
            <Route path="snapshots/:snapshotId" element={<SnapshotDetails />} />
            <Route path="queries" element={<ServerQueryOverview />} />
            <Route path="queries/explains" element={<ExplainOverview />} />
            <Route path="workbooks" element={<QueryTuning tab="workbook" />} />
            <Route path="queries/search" element={<ServerQueryOverview />} />
            <Route path="config" element={<PostgresSettingList />} />
            <Route
              path="config/:settingName"
              element={<PostgresSettingDetails />}
            />
            <Route path="system" element={<SystemDetails tab="overview" />} />
            <Route path="system/cpu" element={<SystemDetails tab="cpu" />} />
            <Route
              path="system/memory"
              element={<SystemDetails tab="memory" />}
            />
            <Route
              path="system/storage"
              element={<SystemDetails tab="storage" />}
            />
            <Route
              path="system/network"
              element={<SystemDetails tab="network" />}
            />
          </Route>
          <Route element={<WithSubscriptionCheck requireFeature="logs" />}>
            <Route path="logs" element={<LogInsights />} />
            <Route path="logs/:classification" element={<LogInsights />} />
          </Route>
          <Route
            element={<WithSubscriptionCheck requireFeature="schemaStats" />}
          >
            <Route path="schema" element={<ServerSchemaOverview />} />
          </Route>
          <Route element={<WithSubscriptionCheck requireFeature="checkUp" />}>
            <Route path="alerts" element={<CheckUp tab="alerts" />} />
            <Route path="alerts/configs" element={<AlertConfig />} />
            <Route path="checks" element={<CheckUp tab="checks" />} />
            <Route path="checks/:checkGroup/:checkName">
              <Route index element={<CheckUpDetails tab="triggered" />} />
              <Route
                path="acknowledged"
                element={<CheckUpDetails tab="acknowledged" />}
              />
              <Route
                path="resolved"
                element={<CheckUpDetails tab="resolved" />}
              />
              <Route
                path="configure"
                element={<CheckUpDetails tab="configure" />}
              />
              <Route path=":issueId" element={<IssueDetailPage />} />
            </Route>
          </Route>
          <Route
            element={
              <WithSubscriptionCheck requireFeature="activitySnapshotsVacuumAdvisor" />
            }
          >
            <Route path="vacuums" element={<VacuumAdvisor tab="overview" />} />
            <Route
              path="vacuums/bloat"
              element={<VacuumAdvisor tab="bloat" />}
            />
            <Route
              path="vacuums/freezing"
              element={<VacuumAdvisor tab="freezing" />}
            />
            <Route
              path="vacuums/performance"
              element={<VacuumAdvisor tab="performance" />}
            />
            <Route
              path="vacuums/activity"
              element={<VacuumAdvisor tab="activity" />}
            />
            <Route
              path="vacuums/:vacuumRunIdentity"
              element={<VacuumDetails />}
            />
          </Route>
        </Route>
        <Route path="/databases/:databaseId">
          <Route element={<WithSubscriptionCheck />}>
            <Route index element={<Dashboard />} />
            <Route path="backends" element={<BackendList tab="backends" />} />
            <Route
              path="wait_events"
              element={<BackendList tab="wait_events" />}
            />
            <Route
              path="indexadvisor"
              element={<IndexAdvisorOverview tab="overview" />}
            />
            <Route
              path="indexadvisor/indexing_engine"
              element={<IndexAdvisorOverview tab="indexing_engine" />}
            />
            <Route
              path="indexadvisor/index_unused"
              element={<IndexAdvisorOverview tab="index_unused" />}
            />
            <Route
              path="indexadvisor/status"
              element={<IndexAdvisorOverview tab="status" />}
            />
            <Route
              path="indexadvisor/config"
              element={<IndexAdvisorOverview tab="config" />}
            />
            <Route
              path="queries"
              element={<QueryPerformance tab="overview" />}
            />
            <Route
              path="queries/explains"
              element={
                hasExplainUpload ? (
                  <QueryPerformance tab="explains" />
                ) : (
                  <ExplainOverview />
                )
              }
            />
            <Route path="workbooks" element={<QueryTuning tab="workbook" />} />
            <Route path="workbooks/new" element={<ReviewQuery />} />
            <Route path="workbooks/:workbookId" element={<ExplainWorkbook />} />
            <Route
              path="workbooks/:workbookId/compare"
              element={<ExplainWorkbook tab="compare" />}
            />
            <Route
              path="workbooks/:workbookId/params"
              element={<ExplainWorkbook tab="params" />}
            />
            <Route
              path="workbooks/:workbookId/variants/new"
              element={<RewriteQuery />}
            />
            <Route
              path="workbooks/:workbookId/variants/:variantId"
              element={<ExplainVariant />}
            />
            <Route
              path="workbooks/:workbookId/variants/:variantId/run"
              element={<ExplainVariant tab="run" />}
            />
            <Route
              path="workbooks/:workbookId/variants/:variantId/results/:resultId"
              element={<ExplainResult />}
            />
            <Route
              path="queries/search"
              element={<QueryPerformance tab="search" />}
            />
            <Route path="queries/:queryId">
              <Route index element={<QueryDetails tab="overview" />} />
              <Route
                path="indexcheck"
                element={<QueryDetails tab="indexcheck" />}
              />
              <Route
                path="indexadvisor"
                element={<QueryDetails tab="indexadvisor" />}
              />
              <Route path="samples" element={<QueryDetails tab="samples" />} />
              <Route
                path="explains"
                element={<QueryDetails tab="explains" />}
              />
              <Route
                path="explains/:explainId"
                element={<QueryDetails tab="explains" />}
              />
              <Route path="tags" element={<QueryDetails tab="tags" />} />
              <Route path="logs" element={<QueryDetails tab="logs" />} />
            </Route>
          </Route>
          <Route element={<WithSubscriptionCheck requireFeature="logs" />}>
            <Route path="logs" element={<LogInsights />} />
            <Route path="logs/:classification" element={<LogInsights />} />
          </Route>
          <Route
            element={<WithSubscriptionCheck requireFeature="schemaStats" />}
          >
            <Route path="functions" element={<SchemaFunctionList />} />
            <Route
              path="functions/:schemaFunctionId"
              element={<SchemaFunctionDetails />}
            />
            <Route path="indexes" element={<SchemaIndexList />} />
            <Route
              path="indexes/:schemaIndexId"
              element={<SchemaIndexDetails />}
            />
            <Route path="tables" element={<SchemaTableList />} />
            <Route path="tables/:tableId">
              <Route index element={<SchemaTableDetails tab="overview" />} />
              <Route
                path="partitions"
                element={<SchemaTableDetails tab="partitions" />}
              />
              <Route
                path="queries"
                element={<SchemaTableDetails tab="queries" />}
              />
              <Route
                path="columns"
                element={<SchemaTableDetails tab="columns" />}
              />
              <Route
                path="indexes"
                element={<SchemaTableDetails tab="indexes" />}
              />
              <Route
                path="constraints"
                element={<SchemaTableDetails tab="constraints" />}
              />
              <Route
                path="vacuum"
                element={<SchemaTableDetails tab="vacuum" />}
              />
              <Route
                path="index_advisor"
                element={<SchemaTableDetails tab="index_advisor" />}
              />
              <Route
                path="vacuum_simulator"
                element={<SchemaTableDetails tab="vacuum_simulator" />}
              />
            </Route>
            <Route path="views" element={<SchemaViewList />} />
            <Route path="views/:viewId">
              <Route index element={<SchemaViewDetails tab="overview" />} />
              <Route
                path="columns"
                element={<SchemaViewDetails tab="columns" />}
              />
              <Route
                path="indexes"
                element={<SchemaViewDetails tab="indexes" />}
              />
            </Route>
          </Route>
          <Route element={<WithSubscriptionCheck requireFeature="checkUp" />}>
            <Route path="checks" element={<CheckUp tab="checks" />} />
            <Route path="checks/:checkGroup/:checkName">
              <Route index element={<CheckUpDetails tab="triggered" />} />
              <Route
                path="acknowledged"
                element={<CheckUpDetails tab="acknowledged" />}
              />
              <Route
                path="resolved"
                element={<CheckUpDetails tab="resolved" />}
              />
              <Route
                path="configure"
                element={<CheckUpDetails tab="configure" />}
              />
              <Route path=":issueId" element={<IssueDetailPage />} />
            </Route>
          </Route>
          <Route
            element={
              <WithSubscriptionCheck requireFeature="activitySnapshotsConnections" />
            }
          >
            <Route path="backends/:backendLocator">
              <Route index element={<BackendDetails tab="overview" />} />
              <Route
                path="queries"
                element={<BackendDetails tab="queries" />}
              />
              <Route
                path="wait_events"
                element={<BackendDetails tab="wait_events" />}
              />
              <Route path="logs" element={<BackendDetails tab="logs" />} />
            </Route>
          </Route>
          <Route
            element={
              <WithSubscriptionCheck requireFeature="activitySnapshotsVacuumAdvisor" />
            }
          >
            <Route
              path="vacuums/bloat"
              element={<VacuumAdvisor tab="bloat" />}
            />
            <Route
              path="vacuums/freezing"
              element={<VacuumAdvisor tab="freezing" />}
            />
            <Route
              path="vacuums/activity"
              element={<VacuumAdvisor tab="activity" />}
            />
          </Route>
        </Route>
        <Route
          path="/users"
          element={
            <LegacyContent
              contentKey="users/edit"
              legacyContent={legacyContent}
            />
          }
        />
        <Route
          path="/users/edit"
          element={
            <LegacyContent
              contentKey="users/edit"
              legacyContent={legacyContent}
            />
          }
        />
        <Route
          path="*"
          element={
            legacyContent ? (
              <>{legacyContent}</>
            ) : (
              "no matching route"
            ) /*<Loading error />*/
          }
        />
      </Routes>
    </>
  );
};

const SchemaProcessingDisabledFlash: React.FunctionComponent = () => {
  return (
    <ShowFlash
      level="alert"
      msg={
        <>
          This server is over the table limit for{" "}
          <a target="_blank" href="https://pganalyze.com/pricing">
            your pganalyze plan
          </a>{" "}
          and schema information could not be processed. Many pganalyze features
          will not work correctly. You can filter the monitored tables to stay
          below the limit by using the{" "}
          <a
            target="_blank"
            href="https://pganalyze.com/docs/collector/settings#schema-filter-settings"
          >
            <code>ignore_schema_regexp</code>
          </a>{" "}
          collector setting.
        </>
      }
    />
  );
};

export default Page;
