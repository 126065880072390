import { useMutation } from "@apollo/client";
import React from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

import WORKBOOK_MUTATION from "./Mutation.workbook.graphql";
import VARIANT_MUTATION from "./Mutation.variant.graphql";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import WORKBOOK_LIST_QUERY from "../Query.graphql";
import {
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import {
  DeleteExplainWorkbook,
  DeleteExplainWorkbookVariables,
} from "./types/DeleteExplainWorkbook";
import {
  DeleteExplainQuery,
  DeleteExplainQueryVariables,
} from "./types/DeleteExplainQuery";
import { useRoutes } from "utils/routes";
import Popover from "components/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFingerprint,
  faPlus,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import QueryTags, { QueryTagType } from "components/QueryTags";
import Button from "components/Button";
import moment from "moment";
import { formatTimestampLong } from "utils/format";

export const ExplainWorkbookSidebar = ({
  workbook,
}: {
  workbook: ExplainWorkbookType;
}) => {
  const { databaseId } = useParams();
  const { databaseWorkbooks } = useRoutes();
  const navigate = useNavigate();
  const [deleteExplainWorkbook] = useMutation<
    DeleteExplainWorkbook,
    DeleteExplainWorkbookVariables
  >(WORKBOOK_MUTATION);

  const handleDeleteWorkbook = () => {
    if (window.confirm(`Delete workbook "${workbook.name}"?`)) {
      deleteExplainWorkbook({
        variables: { workbookId: workbook.id },
        refetchQueries: [
          {
            query: WORKBOOK_LIST_QUERY,
            variables: {
              databaseId: workbook.databaseId,
            },
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          navigate(databaseWorkbooks(databaseId));
        },
      });
    }
  };
  return (
    <div className="w-[300px]">
      <SidebarNav workbook={workbook} />
      <hr />
      <div className="leading-7 mt-0 font-medium">Owner</div>
      <div className="mb-2">{workbook.user.fullname || "Deleted User"}</div>
      <div className="leading-7 mt-0 font-medium">Workbook description</div>
      <div className="mb-2">
        {workbook.description ?? (
          <span className="italic text-[#606060]">No description</span>
        )}
      </div>
      <div className="leading-7 mt-0 font-medium">Last edit</div>
      <div className="mb-2">
        {formatTimestampLong(moment.unix(workbook.lastActivityAt))}
      </div>
      <hr />
      <Button bare onClick={handleDeleteWorkbook} className="!text-[#d43f3a]">
        <FontAwesomeIcon icon={faTrashAlt} /> Delete workbook
      </Button>
    </div>
  );
};

const SidebarNav = ({ workbook }: { workbook: ExplainWorkbookType }) => {
  const { databaseId } = useParams();

  const {
    databaseWorkbook,
    databaseWorkbookVariant,
    databaseWorkbookVariantNew,
  } = useRoutes();
  const menuList: { to: string; title: string; end: boolean }[] = [
    {
      to: databaseWorkbook(databaseId, workbook.id),
      title: "All Query Plans",
      end: true,
    },
    {
      to: databaseWorkbookVariant(
        databaseId,
        workbook.id,
        workbook.baselineQuery.id,
      ),
      title: "Baseline",
      end: false,
    },
  ];
  workbook.explainQueries.forEach((explainQuery) => {
    menuList.push({
      to: databaseWorkbookVariant(databaseId, workbook.id, explainQuery.id),
      title: explainQuery.name,
      end: false,
    });
  });

  return (
    <div className="font-medium flex flex-col gap-2">
      <div>
        <Link
          to={databaseWorkbookVariantNew(databaseId, workbook.id)}
          className="btn btn-success w-full !px-4 !py-2.5 !font-medium"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-1" /> New Query Variant
        </Link>
      </div>
      {menuList.map((menu, idx) => {
        return (
          <NavLink
            to={menu.to}
            className={({ isActive }) =>
              isActive
                ? "py-2 px-2.5 bg-[#F9FAFB] rounded-md border border-[#E8E8EE] text-[#19152C] hover:text-[#19152C]"
                : "py-2 px-2.5 rounded-md border border-transparent text-[#606060] hover:text-[#19152C] hover:bg-[#F9FAFB]"
            }
            end={menu.end}
            key={`menu${idx}`}
          >
            {menu.title}
          </NavLink>
        );
      })}
    </div>
  );
};

const ExplainVariantSidebar = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery?: ExplainQueryType;
}) => {
  const { databaseId } = useParams();
  const { databaseQuery, databaseWorkbook } = useRoutes();
  const navigate = useNavigate();
  const focusedQuery = explainQuery || workbook.baselineQuery;
  const [deleteExplainQuery] = useMutation<
    DeleteExplainQuery,
    DeleteExplainQueryVariables
  >(VARIANT_MUTATION);
  const handleDeleteVariant = () => {
    if (window.confirm(`Delete variant "${explainQuery.name}"?`)) {
      deleteExplainQuery({
        variables: { explainQueryId: explainQuery.id },
        refetchQueries: [
          {
            query: WORKBOOK_DETAIL_QUERY,
            variables: {
              workbookId: workbook.id,
              databaseId: workbook.databaseId,
            },
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          navigate(databaseWorkbook(databaseId, workbook.id));
        },
      });
    }
  };

  const queryTags: QueryTagType[] = [];
  if (focusedQuery.query) {
    queryTags.push(
      ...[
        {
          id: "fingerprint",
          key: (
            <Popover
              content="A query fingerprint represents the abstracted form of a query and enables the grouping of similar queries together. This value is calculated using the pg_query library."
              popupClassName="!text-[12px]"
            >
              <FontAwesomeIcon
                icon={faFingerprint}
                className="text-[#666] text-[10px]"
              />{" "}
              fingerprint
            </Popover>
          ),
          value: focusedQuery.queryFingerprint,
        },
        {
          id: "role",
          key: (
            <>
              <FontAwesomeIcon
                icon={faUser}
                className="text-[#666] text-[10px]"
              />{" "}
              role
            </>
          ),
          value: focusedQuery.query.postgresRole.name,
        },
      ],
    );
  }

  return (
    <div className="w-[300px]">
      <SidebarNav workbook={workbook} />
      <hr />
      <div className="leading-7 mt-0 font-medium">Query</div>
      <div className="mb-3">
        {focusedQuery.query ? (
          <Link to={databaseQuery(databaseId, focusedQuery.query.id)}>
            #{focusedQuery.query.id}
          </Link>
        ) : (
          <span className="italic text-[#606060]">
            No match with existing queries
          </span>
        )}
      </div>
      <div className="leading-7 mt-0 font-medium">Query tags</div>
      <div className="mb-3">
        {queryTags.length > 0 ? (
          <QueryTags tags={queryTags} />
        ) : (
          <span className="italic text-[#606060]">No query tags</span>
        )}
      </div>
      <hr />
      {focusedQuery.id !== workbook.baselineQuery.id && (
        <Button bare onClick={handleDeleteVariant} className="!text-[#d43f3a]">
          <FontAwesomeIcon icon={faTrashAlt} /> Remove variant
        </Button>
      )}
    </div>
  );
};

export default ExplainVariantSidebar;
