import Panel from "components/Panel";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import UPDATE_MUTATION from "./Mutation.update.graphql";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_aliasParamMapList as AliasParamMapType,
} from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import {
  convertParamValue,
  ParameterSetsType,
  ParamSetType,
  stringifyValue,
} from "../ExplainWorkbook/util";
import Callout from "components/Callout";
import {
  faCheckCircle,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import {
  UpdateExplainParameterSets,
  UpdateExplainParameterSetsVariables,
} from "./types/UpdateExplainParameterSets";
import { useNavigate, useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";
import { ExplainQueryPanel } from "../ExplainVariant";
import PanelSection from "components/PanelSection";
import PageContent from "components/PageContent";
import { VariantCreationHeader } from "../RewriteQuery";

const EditParameterSets = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery: ExplainQueryType;
}) => {
  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const { databaseWorkbookVariantRun } = useRoutes();
  const navigate = useNavigate();
  // key = setId, value = param set (params to newly add)
  const [newParameterSets, setNewParameterSets] = useState<ParameterSetsType>(
    {},
  );

  const [updateExplainParameterSets] = useMutation<
    UpdateExplainParameterSets,
    UpdateExplainParameterSetsVariables
  >(UPDATE_MUTATION, {
    refetchQueries: [
      {
        query: WORKBOOK_DETAIL_QUERY,
        variables: { workbookId: workbook.id, databaseId },
      },
    ],
  });

  const expectedAliasesLength = workbook.parameterRefAliases.length;
  // For the case the workbook was initially created without params but new param set is added
  const newParamNotFilled =
    workbook.parameterSets.length === 0 && expectedAliasesLength > 0;
  const missingAliasCount = newParamNotFilled
    ? expectedAliasesLength
    : expectedAliasesLength - workbook.parameterSets[0].paramValues.length;
  const allValuesSet =
    !newParamNotFilled &&
    Object.keys(newParameterSets).length === workbook.parameterSets.length &&
    Object.values(newParameterSets).every(
      (paramSet) => Object.keys(paramSet).length === missingAliasCount,
    );

  const handleRunExplain = () => {
    if (!allValuesSet) {
      setErrorMessage(
        "Please enter values, types, or NULL flags for all parameters.",
      );
      return;
    }
    const convertedParameterSets = Object.fromEntries(
      Object.entries(newParameterSets).map(([setId, paramSet]) => {
        const newSet = Object.entries(paramSet).map(([pkey, pvalue]) => {
          return [
            pkey,
            {
              value: convertParamValue(pvalue),
            },
          ];
        });
        return [setId, Object.fromEntries(newSet)];
      }),
    );
    updateExplainParameterSets({
      variables: {
        workbookId: workbook.id,
        parameterSets: convertedParameterSets,
      },
      onCompleted: () => {
        navigate(
          databaseWorkbookVariantRun(
            workbook.databaseId,
            workbook.id,
            explainQuery.id,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };
  const title = (
    <VariantCreationHeader
      name={explainQuery.name}
      focused="step1"
      rightSection={
        <button
          className="btn btn-success !px-4"
          disabled={!allValuesSet}
          onClick={handleRunExplain}
        >
          Run EXPLAIN...
        </button>
      }
      disableNameEdit
    />
  );

  // TODO: show component to allow creating a new param set when newParamNotFilled
  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      title={title}
      pageCategory="explains"
      pageName="workbooks"
    >
      <ExplainQueryPanel workbook={workbook} explainQuery={explainQuery} />
      <Callout
        title="Parameter Sets need an update"
        variant="warning"
        className="mb-4"
      >
        New parameters are added with this new variant. Please update parameter
        set values.
      </Callout>
      <Panel title="Parameter Sets">
        {workbook.aliasParamMapList.map((val) => {
          return (
            <PanelSection key={val.id}>
              <ParameterSetCell
                currParameterSet={val}
                workbook={workbook}
                setNewParameterSets={setNewParameterSets}
              />
            </PanelSection>
          );
        })}
      </Panel>
      {errorMessage && <div className="text-[#FF0000]">{errorMessage}</div>}
    </PageContent>
  );
};

const ParameterSetCell = ({
  currParameterSet,
  workbook,
  setNewParameterSets,
}: {
  currParameterSet: AliasParamMapType;
  workbook: ExplainWorkbookType;
  setNewParameterSets: React.Dispatch<React.SetStateAction<ParameterSetsType>>;
}) => {
  const [paramSet, setParamSet] = useState<ParamSetType>({});

  const missingAliases = missingValueAliases(workbook, currParameterSet.id);

  // TODO: lots of same functions exist in CreateParameterSetPanel, combine them
  const handleParamValuesChange = (alias: string, value: string) => {
    const prevParam = paramSet[alias];
    const newSet = { ...paramSet };
    if (value === "" && !prevParam?.type && !prevParam?.isNull) {
      // remove from the set when the value becomes an empty string without type or flagged as null
      delete newSet[alias];
    } else {
      newSet[alias] = {
        value: value,
        type: prevParam?.type,
        isNull: prevParam?.isNull,
      };
    }
    setParamSet(newSet);
    setNewParameterSets((prevState) => {
      return {
        ...prevState,
        [currParameterSet.id]: newSet,
      };
    });
  };
  const handleParamNullChange = (alias: string, isNull: boolean) => {
    const prevParam = paramSet[alias];
    const newSet = { ...paramSet };
    // when null is checked but there is an existing value, empty it
    newSet[alias] = {
      value: isNull ? undefined : prevParam?.value,
      type: prevParam?.type,
      isNull: isNull,
    };
    setParamSet(newSet);
    setNewParameterSets((prevState) => {
      return {
        ...prevState,
        [currParameterSet.id]: newSet,
      };
    });
  };
  // TODO: support specifying type

  const inputFields: React.JSX.Element[] = [];
  missingAliases.forEach((alias) => {
    inputFields.push(
      <div key={`label-${alias}`} className="font-medium">
        ${alias}
      </div>,
    );
    inputFields.push(
      <input
        key={`input-${alias}`}
        className="bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5 disabled:bg-[#eee]"
        type="text"
        value={paramSet[alias]?.value ?? ""}
        disabled={paramSet[alias]?.isNull}
        onChange={(e) => {
          handleParamValuesChange(alias, e.target.value);
        }}
      />,
    );
    inputFields.push(
      <div key={`null-${alias}`}>
        NULL:{" "}
        <input
          type="checkbox"
          checked={paramSet[alias]?.isNull || false}
          onChange={(e) => handleParamNullChange(alias, e.target.checked)}
        />
      </div>,
    );
  });
  return (
    <div>
      <div>
        <div className="flex gap-2 items-center">
          {missingAliases.length === 0 ? (
            <FontAwesomeIcon icon={faCheckCircle} />
          ) : (
            <FontAwesomeIcon icon={faTriangleExclamation} />
          )}
          <div className="font-medium">{currParameterSet.name}</div>
        </div>
        <div>
          <pre className="border-none m-0 p-0 bg-none bg-transparent whitespace-pre-wrap">
            {Object.entries(currParameterSet.parameters as ParamSetType).map(
              ([key, value], i, arr) => {
                const missing = missingAliases.includes(key.slice(1));
                const missingClass = missing ? "text-[#A16006]" : "";
                return (
                  <React.Fragment key={key}>
                    <span className={missingClass}>
                      {missing
                        ? key
                        : `${key} = ${stringifyValue(value.value)}`}
                    </span>
                    {i + 1 < arr.length && ", "}
                  </React.Fragment>
                );
              },
            )}
          </pre>
        </div>
      </div>
      {missingAliases.length > 0 && (
        <div>
          <div className="grid grid-cols-[min-content_1fr_80px] gap-2 mb-2 mt-2 items-center">
            {Object.entries(inputFields).map(([_, elem]) => elem)}
          </div>
        </div>
      )}
    </div>
  );
};

export const missingValueAliases = (
  workbook: ExplainWorkbookType,
  setId: string,
) => {
  const existingParam = workbook.parameterSets.find((val) => val.id === setId);
  const expectedAliases = workbook.parameterRefAliases;
  return expectedAliases.slice(existingParam.paramValues.length);
};

export default EditParameterSets;
