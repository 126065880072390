import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useMutation } from "@apollo/client";
import ModalContainer from "components/ModalContainer";

import { valueTypeValues } from "../ExplainWorkbook/util";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import { ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType } from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import UPDATE_WORKBOOK_MUTATION from "../SetUpSamples/Mutation.update.graphql";
import {
  UpdateExplainWorkbook,
  UpdateExplainWorkbookVariables,
} from "../SetUpSamples/types/UpdateExplainWorkbook";

const EditParameterSettingsPanel = ({
  onDismiss,
  workbook,
}: {
  onDismiss: () => void;
  workbook: ExplainWorkbookType;
}) => {
  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [paramTypes, setParamTypes] = useState(workbook.parameterSetTypes);

  const [updateExplainWorkbook] = useMutation<
    UpdateExplainWorkbook,
    UpdateExplainWorkbookVariables
  >(UPDATE_WORKBOOK_MUTATION);

  const handleUpdateWorkbook = () => {
    // convert null types to an empty string as a workaround of Rails deep_munge
    updateExplainWorkbook({
      variables: {
        workbookId: workbook.id,
        parameterSetTypes: paramTypes.map((val) => (val ? val : "")),
      },
      refetchQueries: [
        {
          query: WORKBOOK_DETAIL_QUERY,
          variables: { workbookId: workbook.id, databaseId },
        },
      ],
      onCompleted: () => {
        setErrorMessage("");
        onDismiss();
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleParamTypeChange = (idx: number, type: string) => {
    const prevType = paramTypes[idx];
    const newTypes = [...paramTypes];
    if (type === "" && prevType != null) {
      // reset type (to fallback to auto detect)
      newTypes[idx] = null;
    } else {
      newTypes[idx] = type;
    }
    setParamTypes(newTypes);
  };
  const inputFields = workbook.parameterRefAliases.map((alias, idx) => {
    const paramType = paramTypes[idx];
    const aliasStr = `$${alias}`;
    // TODO: allow editing alias too
    return [
      <div key={`label-${idx}`}>{aliasStr}</div>,
      <select
        key={`type-${idx}`}
        value={paramType ?? ""}
        onChange={(e) => handleParamTypeChange(idx, e.target.value)}
        className="bg-inherit"
      >
        <option value="">Auto-detect</option>
        {valueTypeValues.map((typeName) => {
          return (
            <option key={typeName} value={typeName}>
              {typeName}
            </option>
          );
        })}
      </select>,
    ];
  });

  return (
    <ModalContainer
      title="Edit Parameter Settings"
      layout="centered"
      onClose={onDismiss}
    >
      <div>
        <div className="grid grid-cols-2 gap-2 mb-2 items-center font-medium">
          <div>Name</div>
          <div>Param Type</div>
          {Object.values(inputFields)}
        </div>
      </div>
      <div className="mt-2 text-[#FF0000]">{errorMessage}</div>
      <div className="text-right mt-4">
        <button className="btn !px-10" onClick={onDismiss}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={handleUpdateWorkbook}>
          Update Parameter Settings
        </button>
      </div>
    </ModalContainer>
  );
};

export default EditParameterSettingsPanel;
