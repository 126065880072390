import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import QUERY from "./Query.graphql";
import {
  ExplainWorkbooks as ExplainWorkbooksType,
  ExplainWorkbooksVariables,
} from "./types/ExplainWorkbooks";
import Loading from "components/Loading";
import Panel from "components/Panel";
import Grid from "components/Grid";
import { formatTimestampShort } from "utils/format";
import moment from "moment";
import { useRoutes } from "utils/routes";
import FilterSearch from "components/FilterSearch";
import { makeFilter } from "utils/filter";

const ExplainWorkbookList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { databaseWorkbook, databaseWorkbooksNew } = useRoutes();

  const { databaseId, serverId } = useParams();
  const { loading, error, data } = useQuery<
    ExplainWorkbooksType,
    ExplainWorkbooksVariables
  >(QUERY, {
    variables: { databaseId, serverId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const secondaryTitle = (
    <FilterSearch initialValue={searchTerm} onChange={setSearchTerm} />
  );

  const filteredData = data.getExplainWorkbooks.filter(
    makeFilter(searchTerm, "name"),
  );

  return (
    <>
      <div className="flex justify-end mb-2">
        <Link to={databaseWorkbooksNew(databaseId)} className="btn btn-success">
          New Workbook
        </Link>
      </div>
      <Panel title="All Workbooks" secondaryTitle={secondaryTitle}>
        <Grid
          className="grid-cols-[1fr_200px_300px]"
          data={filteredData}
          defaultSortBy="lastActivityAt"
          columns={[
            {
              field: "name",
              header: "Workbook",
              renderer: function NameCell({ rowData, fieldData }) {
                return (
                  <div className="flex flex-col">
                    <Link to={databaseWorkbook(databaseId, rowData.id)}>
                      {fieldData}
                    </Link>
                    {rowData.user?.fullname}
                  </div>
                );
              },
            },
            {
              field: "variantsCount",
              header: "Variant",
              style: "number",
            },
            {
              field: "lastActivityAt",
              header: "Last Activity",
              defaultSortOrder: "desc",
              className: "text-right",
              headerClassName: "text-right",
              renderer: function LastActivityCell({ fieldData }) {
                return formatTimestampShort(moment.unix(fieldData));
              },
            },
          ]}
        />
      </Panel>
    </>
  );
};

export default ExplainWorkbookList;
