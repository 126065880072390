/**
 * Return a string of at most `limit` characters, replacing any overflow with an
 * ellipsis.
 *
 * @param str string to elide
 * @param limit maximum number of characters
 * @returns elided string
 */
export function elide(str: string, limit: number) {
  if (str.length <= limit) {
    return str;
  }

  return str.slice(0, limit - 1) + "…";
}
