import React, { useEffect, useState } from "react";

import NodeDetails from "components/ExplainSidebar/NodeDetails";
import Source from "components/ExplainSidebar/NodeDetails/Source";
import PillButtonBar from "components/PillButtonBar";

import { AnnotatedPlan } from "types/explain";

import {
  useCurrentComparePlan,
  useCurrentPlan,
} from "components/WithExplainPlan";
import { useLocation } from "react-router-dom";
import ExplainCompareSummary from "./ExplainCompareSummary";
import { findPlanIndexes } from "utils/explain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { useSelectedNode } from "components/Explain/WithNodeSelection";
import { usePrevious } from "utils/hooks";

const ExplainCompareSidebar = ({
  blockSize,
  databaseId,
}: {
  blockSize: number;
  databaseId: string;
}) => {
  const planA = useCurrentPlan();
  const planB = useCurrentComparePlan();
  const { hash } = useLocation();
  const usingComparisonPlan = hash.includes("compare");
  const activePlan = usingComparisonPlan ? planB : planA;
  const activePlanLabel = usingComparisonPlan ? "Plan B" : "Plan A";

  const [selectedNode] = useSelectedNode();
  const selectedId = selectedNode?.extra.id;
  const previousSelectedId = usePrevious(selectedId);
  const [section, setSection] = useState<
    "summary" | "node details" | "node source"
  >("summary");
  const switchToNodeDetails =
    section === "summary" && selectedId && selectedId !== previousSelectedId;
  useEffect(() => {
    if (switchToNodeDetails) {
      setSection("node details");
    }
  }, [switchToNodeDetails]);

  const opts = [
    { value: "summary", label: "Summary" },
    { value: "node details", label: "Node Details" },
    { value: "node source", label: "Node Source" },
  ] as const;

  return (
    <div>
      <PillButtonBar
        opts={opts}
        selected={section}
        onChange={(value) => setSection(value)}
      />
      <div className="mt-4">
        {section === "summary" ? (
          planA && planB ? (
            <>
              <ExplainCompareSummary planA={planA} planB={planB} />
              <hr />
              <div>
                <h4 className="text-[#606060]">Index usage</h4>
                <ExplainIndexUsage planA={planA.plan} planB={planB.plan} />
              </div>
            </>
          ) : (
            <div>Select plans to compare</div>
          )
        ) : section === "node details" ? (
          <>
            {activePlan && selectedId && (
              <div className="mb-2 flex align-baseline">
                From {activePlanLabel}
              </div>
            )}
            <NodeDetails
              databaseId={databaseId}
              blockSize={blockSize}
              annotations={activePlan.plan.annotations}
            />
          </>
        ) : section === "node source" ? (
          <Source />
        ) : null}
      </div>
    </div>
  );
};

const ExplainIndexUsage = ({
  planA,
  planB,
}: {
  planA: AnnotatedPlan;
  planB: AnnotatedPlan;
}) => {
  const planAIndexes = findPlanIndexes(planA.plan);
  const planBIndexes = findPlanIndexes(planB.plan);
  const allIndexes = Array.from(
    new Set(Array.from(planAIndexes).concat(Array.from(planBIndexes))),
  ).sort();
  if (allIndexes.length === 0) {
    return <div>No indexes were used by either execution plan.</div>;
  }
  return (
    <table className="w-full table-fixed border-separate border-spacing-1">
      <thead>
        <tr>
          <th className="w-3">A</th>
          <th className="w-3">B</th>
          <th>Index</th>
        </tr>
      </thead>
      <tbody>
        {allIndexes.map((index, i) => {
          const footnoteNum = i + 1;
          return (
            <tr key={index}>
              <td className="align-top text-sm">
                {planAIndexes.has(index) && <FontAwesomeIcon icon={faCheck} />}
              </td>
              <td className="align-top text-sm">
                {planBIndexes.has(index) && <FontAwesomeIcon icon={faCheck} />}
              </td>
              <td title={index} className="overflow-hidden text-ellipsis">
                {footnoteNum}.&nbsp;{index}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExplainCompareSidebar;
