import PageContent from "components/PageContent";
import Panel from "components/Panel";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import QUERY from "./Query.graphql";
import CREATE_MUTATION from "./Mutation.create.graphql";
import DELETE_MUTATION from "./Mutation.delete.graphql";
import DELETE_WORKBOOK_MUTATION from "../ExplainVariantSidebar/Mutation.workbook.graphql";
import UPDATE_WORKBOOK_MUTATION from "./Mutation.update.graphql";
import WORKBOOK_LIST_QUERY from "../Query.graphql";
import {
  QuerySamplesForWorkbook,
  QuerySamplesForWorkbookVariables,
  QuerySamplesForWorkbook_getQuerySamples as QuerySample,
} from "./types/QuerySamplesForWorkbook";
import { formatMs, formatTimestampShort } from "utils/format";
import moment from "moment";
import Grid, { MsCell } from "components/Grid";
import SQL from "components/SQL";
import Identicon from "components/Identicon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/pro-regular-svg-icons";
import PanelSection from "components/PanelSection";
import { useRoutes } from "utils/routes";
import { ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType } from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import Loading from "components/Loading";
import {
  CreateExplainParameterSets,
  CreateExplainParameterSetsVariables,
} from "./types/CreateExplainParameterSets";
import {
  DeleteExplainParameterSets,
  DeleteExplainParameterSetsVariables,
} from "./types/DeleteExplainParameterSets";
import {
  UpdateExplainWorkbook,
  UpdateExplainWorkbookVariables,
} from "./types/UpdateExplainWorkbook";
import {
  aliasParamMapToString,
  jsonParametersToString,
} from "../ExplainWorkbook/util";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import CreateParameterSetPanel from "../CreateParameterSetPanel";
import EditParameterSetPanel from "../EditParameterSetPanel";
import {
  DeleteExplainWorkbook,
  DeleteExplainWorkbookVariables,
} from "../ExplainVariantSidebar/types/DeleteExplainWorkbook";
import Button from "components/Button";
import { QueryTextArea } from "../ReviewQuery";
import EditParameterSettingsPanel from "../EditParameterSettingsPanel";
import ModalContainer from "components/ModalContainer";
import {
  faCircle1,
  faCircle2,
  faCircle3,
} from "@fortawesome/pro-solid-svg-icons";

const SetUpSamples = ({ workbook }: { workbook: ExplainWorkbookType }) => {
  const { databaseId } = useParams();
  const [sampleIds, setSampleIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showCreateParameterSetPanel, setShowCreateParameterSetPanel] =
    useState(false);
  const [parameterSetIdForEdit, setParameterSetIdForEdit] = useState(null);
  const [showParameterSettingsEditPanel, setShowParameterSettingsEditPanel] =
    useState(false);
  const {
    databaseQueryExplain,
    databaseWorkbooks,
    databaseWorkbookVariantRun,
  } = useRoutes();
  const navigate = useNavigate();
  const baselineQuery = workbook.baselineQuery;

  const [createExplainParameterSets] = useMutation<
    CreateExplainParameterSets,
    CreateExplainParameterSetsVariables
  >(CREATE_MUTATION);
  const [deleteExplainParameterSets] = useMutation<
    DeleteExplainParameterSets,
    DeleteExplainParameterSetsVariables
  >(DELETE_MUTATION);
  const [deleteExplainWorkbook] = useMutation<
    DeleteExplainWorkbook,
    DeleteExplainWorkbookVariables
  >(DELETE_WORKBOOK_MUTATION);

  const { loading, error, data } = useQuery<
    QuerySamplesForWorkbook,
    QuerySamplesForWorkbookVariables
  >(QUERY, {
    variables: {
      databaseId,
      queryFingerprint: baselineQuery.queryFingerprint,
    },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const querySamples = filterSamples(data.getQuerySamples);
  const filteredData = querySamples.filter((sample) =>
    jsonParametersToString(sample.jsonParameters).includes(searchTerm),
  );

  function handleCreateDismiss() {
    setShowCreateParameterSetPanel(false);
  }

  function handleEditDismiss() {
    setParameterSetIdForEdit(null);
  }

  const handleRunExplain = () => {
    createExplainParameterSets({
      variables: {
        databaseId,
        explainQueryId: baselineQuery.id,
        querySampleIds: sampleIds,
        finalize: true,
      },
      refetchQueries: [
        {
          query: WORKBOOK_DETAIL_QUERY,
          variables: { workbookId: workbook.id, databaseId },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        navigate(
          databaseWorkbookVariantRun(
            workbook.databaseId,
            workbook.id,
            baselineQuery.id,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleDeleteWorkbook = () => {
    deleteExplainWorkbook({
      variables: { workbookId: workbook.id },
      refetchQueries: [
        {
          query: WORKBOOK_LIST_QUERY,
          variables: {
            databaseId: workbook.databaseId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        navigate(databaseWorkbooks(databaseId));
      },
    });
  };

  const queryWithNoParams =
    Object.keys(baselineQuery.paramRefAliasMap).length === 0;
  const queryWithSamples = querySamples.length > 0;
  const selectedParameterSetsCount =
    workbook.parameterSets.length + Object.keys(sampleIds).length;

  const paramSetData = workbook.parameterRefAliases.map((val, i) => {
    return {
      alias: `$${val}`,
      type: workbook.parameterSetTypes[i],
    };
  });

  const workbookTitle = (
    <WorkbookCreationHeaderWithWorkbook
      workbook={workbook}
      rightSection={
        <button
          className="btn btn-success"
          disabled={!queryWithNoParams && selectedParameterSetsCount === 0}
          onClick={handleRunExplain}
        >
          Run EXPLAIN...
        </button>
      }
      focused="step2"
    />
  );

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      title={workbookTitle}
      pageCategory="explains"
      pageName="workbooks"
      layout="sidebar"
    >
      {/* main content */}
      <Panel title="Query Samples">
        <PanelSection className="grid gap-4">
          {queryWithSamples ? (
            <div>
              We found the {querySamples.length} representative execution
              samples for this query. For better results, select at least two
              samples: for example the slowest and the fastest samples, or
              samples using distinct query plans.
            </div>
          ) : (
            <div>
              We did not find any query samples to select parameters for this
              query. Create custom samples to run this query with.
            </div>
          )}
          {errorMessage && <div className="text-[#FF0000]">{errorMessage}</div>}
          <QueryTextArea
            queryText={workbook.baselineQuery.queryTextWithAlias}
            className={
              queryWithSamples ? "max-h-[240px] overflow-y-scroll" : ""
            }
          />
          {queryWithSamples && (
            <div className="w-full">
              <input
                className="col-span-2 bg-white rounded border border-gray-300 box-border h-8 leading-5 px-2 py-2 w-full"
                type="text"
                placeholder="Search parameter values..."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
            </div>
          )}
          {queryWithSamples && (
            <Grid
              className="grid-cols-[1fr_140px_140px]"
              data={filteredData}
              defaultSortBy={"runtimeMs"}
              noRowsText="No matching parameters"
              columns={[
                {
                  field: "jsonParameters",
                  header: "Parameter Values",
                  renderer: function ParametersCell({ rowData, fieldData }) {
                    const jsonParams = jsonParametersToString(fieldData);
                    return (
                      <div className="flex gap-2">
                        <div>
                          <input
                            type="checkbox"
                            id="sample_params"
                            checked={!!sampleIds.includes(rowData.id)}
                            onChange={(evt) => {
                              if (evt.target.checked) {
                                if (sampleIds.length > 4) {
                                  setErrorMessage(
                                    "Only up to 5 samples can be selected",
                                  );
                                  return;
                                }
                                setSampleIds([...sampleIds, rowData.id]);
                                setErrorMessage("");
                              } else {
                                setSampleIds(
                                  sampleIds.filter((id) => id !== rowData.id),
                                );
                              }
                            }}
                          />
                        </div>
                        <div title={jsonParams}>
                          <SQL
                            className="!whitespace-nowrap"
                            sql={jsonParams}
                          />
                          <div className="text-[12px] text-[#606060]">
                            {formatTimestampShort(
                              moment.unix(rowData.occurredAt),
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  },
                  disableSort: true,
                },
                {
                  field: "explain",
                  header: "Plan Fingerprint",
                  renderer: function PlanFingerprintCell({
                    fieldData,
                    rowData,
                  }) {
                    const fingerprint = fieldData.fingerprint;
                    return (
                      <Link
                        to={databaseQueryExplain(
                          databaseId,
                          baselineQuery.query.id,
                          rowData.explain.humanId,
                        )}
                      >
                        <Identicon identity={fingerprint} />
                        <span title={fingerprint}>
                          {fingerprint.substring(0, 7)}
                        </span>
                      </Link>
                    );
                  },
                  nullValue: "-",
                },
                {
                  field: "runtimeMs",
                  header: "Runtime",
                  renderer: MsCell,
                  defaultSortOrder: "desc",
                },
              ]}
            />
          )}
        </PanelSection>
      </Panel>
      <div>
        <div className="justify-self-end">
          <Button
            bare
            onClick={handleDeleteWorkbook}
            className="mr-4 !text-[#d43f3a] font-medium"
          >
            Cancel creation
          </Button>
        </div>
      </div>
      {showCreateParameterSetPanel && (
        <CreateParameterSetPanel
          onDismiss={handleCreateDismiss}
          workbook={workbook}
        />
      )}
      {parameterSetIdForEdit && (
        <EditParameterSetPanel
          onDismiss={handleEditDismiss}
          workbook={workbook}
          parameterSetId={parameterSetIdForEdit}
        />
      )}
      {showParameterSettingsEditPanel && (
        <EditParameterSettingsPanel
          onDismiss={() => setShowParameterSettingsEditPanel(false)}
          workbook={workbook}
        />
      )}
      {/* sidebar */}
      <div className="w-[320px]">
        <div className="grid gap-4">
          <div className="font-medium">Query Samples</div>
          <div className="grid gap-2">
            {!queryWithSamples
              ? "No query sample available"
              : sampleIds.length > 0
              ? sampleIds.map((sId) => {
                  const sample = querySamples.find((s) => s.id === sId);
                  return (
                    <div
                      key={`sampleParam-${sId}`}
                      className="rounded border border-[#E8E8EE] p-2 text-[#606060] bg-[#F9FAFB] grid gap-1"
                    >
                      <div className="flex">
                        <div className="grow">
                          {sample.explain && (
                            <div>
                              <Identicon
                                identity={sample.explain.fingerprint}
                              />
                              <span title={sample.explain.fingerprint}>
                                {sample.explain.fingerprint.substring(0, 7)}
                              </span>
                            </div>
                          )}
                        </div>
                        <div>{formatMs(sample.runtimeMs)}</div>
                      </div>
                      <div>
                        <SQL
                          sql={jsonParametersToString(sample.jsonParameters)}
                        />
                      </div>
                      <div>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          title="Delete"
                          className="text-[#CA1515] mr-1 cursor-pointer"
                          onClick={() => {
                            setSampleIds(sampleIds.filter((id) => id !== sId));
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              : "No samples selected yet"}
          </div>
          <div className="font-medium">Custom Samples</div>
          <div className="grid gap-2">
            {queryWithNoParams
              ? "No samples needed"
              : workbook.aliasParamMapList.length > 0
              ? workbook.aliasParamMapList.map((paramMap) => {
                  return (
                    <div
                      key={`customParam-${paramMap.id}`}
                      className="rounded border border-[#E8E8EE] p-2 text-[#606060] bg-[#F9FAFB] grid gap-1"
                    >
                      <div>
                        <SQL sql={aliasParamMapToString(paramMap.parameters)} />
                      </div>
                      <div>
                        <FontAwesomeIcon
                          icon={faEdit}
                          title="Edit"
                          className="text-[#337AB7] mr-3 cursor-pointer"
                          onClick={() => setParameterSetIdForEdit(paramMap.id)}
                        />
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          title="Delete"
                          className="text-[#CA1515] mr-1 cursor-pointer"
                          onClick={() => {
                            deleteExplainParameterSets({
                              variables: {
                                workbookId: workbook.id,
                                parameterSetId: paramMap.id,
                              },
                              onCompleted: () => {
                                setErrorMessage("");
                              },
                              onError: (error) => {
                                setErrorMessage(error.message);
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              : "No custom samples added yet"}
          </div>
          <div>
            <button
              className="btn btn-success"
              onClick={() => setShowCreateParameterSetPanel(true)}
            >
              New Sample
            </button>
          </div>
          <div className="flex items-center justify-between">
            <div className="font-medium">Parameter Settings</div>
            <div>
              <button
                className="w-8 h-8 border rounded border-[#E8E8EE] bg-[#E8E8EE] inline-block"
                onClick={() => setShowParameterSettingsEditPanel(true)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
            </div>
          </div>
          <Grid
            className="grid-cols-[50%_50%]"
            data={paramSetData}
            columns={[
              {
                field: "alias",
                header: "Parameters",
              },
              {
                field: "type",
                header: "Type",
                nullValue: "Auto-detected",
              },
            ]}
          />
        </div>
      </div>
    </PageContent>
  );
};

function filterSamples(samples: QuerySample[]): QuerySample[] {
  // jsonParameters becomes null when params weren't able to extracted (e.g. redacted)
  samples = samples.filter((sample) => sample.jsonParameters !== null);

  // De-duplicate the same parameter set samples
  const existingParameters: Set<string> = new Set();
  samples = samples.filter((sample) => {
    if (sample.explain) {
      // With explain, do not de-duplicate (as it's already selected well per plan at the server level)
      return true;
    }
    const existing = existingParameters.has(
      JSON.stringify(sample.jsonParameters),
    );
    if (existing) {
      return false;
    }
    existingParameters.add(JSON.stringify(sample.jsonParameters));
    return true;
  });

  return samples;
}

const WorkbookNameEditPanel = ({
  onDismiss,
  name,
  description,
  setName,
  setDescription,
  workbook,
}: {
  onDismiss: () => void;
  name: string;
  description: string;
  setName: (name: string) => void;
  setDescription: (desc: string) => void;
  workbook?: ExplainWorkbookType;
}) => {
  const [errorMessage, setErrorMessage] = useState("");

  const [updateExplainWorkbook] = useMutation<
    UpdateExplainWorkbook,
    UpdateExplainWorkbookVariables
  >(UPDATE_WORKBOOK_MUTATION);

  const handleUpdateWorkbook = () => {
    updateExplainWorkbook({
      variables: {
        workbookId: workbook.id,
        name: name,
        description: description,
      },
      refetchQueries: [
        {
          query: WORKBOOK_DETAIL_QUERY,
          variables: {
            workbookId: workbook.id,
            databaseId: workbook.databaseId,
          },
        },
      ],
      onError: (error) => {
        setErrorMessage(error.message);
      },
      onCompleted: () => {
        onDismiss();
      },
    });
  };

  return (
    <ModalContainer title="Edit Workbook" onClose={onDismiss} layout="centered">
      <div className="grid grid-cols-2 mt-4">
        <div className="mb-1 font-medium col-span-2">Name</div>
        <input
          className="col-span-2 bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5"
          type="text"
          onChange={(e) => {
            setName(e.target.value);
            setErrorMessage("");
          }}
          value={name}
          placeholder="Name of workbook"
        />
      </div>
      <div className="grid grid-cols-2 mt-4">
        <div className="mb-1 font-medium col-span-2">
          Description (Optional)
        </div>
        <textarea
          className="col-span-2 bg-white rounded border border-gray-300 box-content leading-5 px-2 py-1.5"
          onChange={(e) => {
            setDescription(e.target.value);
            setErrorMessage("");
          }}
          value={description}
          placeholder="Enter description here"
        />
      </div>
      {errorMessage && (
        <div className="text-[#FF0000] mt-2">{errorMessage}</div>
      )}
      <div className="mt-4">
        <button
          className="btn btn-success"
          onClick={workbook ? handleUpdateWorkbook : onDismiss}
        >
          Update
        </button>
      </div>
    </ModalContainer>
  );
};

export const WorkbookCreationHeaderWithWorkbook = ({
  workbook,
  rightSection,
  focused,
}: {
  workbook: ExplainWorkbookType;
  rightSection: React.ReactNode;
  focused: "step1" | "step2" | "step3";
}) => {
  const [name, setName] = useState(workbook.name);
  const [description, setDescription] = useState(workbook.description || "");

  return (
    <WorkbookCreationHeader
      name={name}
      description={description}
      setName={setName}
      setDescription={setDescription}
      rightSection={rightSection}
      focused={focused}
      workbook={workbook}
    />
  );
};

export const WorkbookCreationHeader = ({
  name,
  description,
  setName,
  setDescription,
  rightSection,
  focused,
  workbook,
}: {
  name: string;
  description: string;
  setName: (name: string) => void;
  setDescription: (desc: string) => void;
  rightSection: React.ReactNode;
  focused: "step1" | "step2" | "step3";
  workbook?: ExplainWorkbookType;
}) => {
  const [showWorkbookNameEditPanel, setShowWorkbookNameEditPanel] =
    useState(false);
  const step1Class = focused === "step1" ? "text-[#29426D]" : "text-[#979797]";
  const step2Class = focused === "step2" ? "text-[#29426D]" : "text-[#979797]";
  const step3Class = focused === "step3" ? "text-[#29426D]" : "text-[#979797]";

  function handleEditShow() {
    setShowWorkbookNameEditPanel(true);
  }
  function handleEditDismiss() {
    setShowWorkbookNameEditPanel(false);
  }

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="basis-1/4">
        <h2
          className="text-[22px] text-[#606060] m-0 py-[9px] font-medium overflow-visible leading-[26px]"
          onClick={handleEditShow}
        >
          {name}
        </h2>
        <small
          className="text-[12px] block mt-[-5px] mb-[10px]"
          onClick={handleEditShow}
        >
          {description || "Add description"}
        </small>
      </div>
      <div className="basis-1/2 flex justify-center align-middle text-[14px] leading-5 gap-5">
        <div className={step1Class}>
          <FontAwesomeIcon icon={faCircle1} /> Review Query
        </div>
        <div className={step2Class}>
          <FontAwesomeIcon icon={faCircle2} /> Set up Samples
        </div>
        <div className={step3Class}>
          <FontAwesomeIcon icon={faCircle3} /> Run EXPLAIN
        </div>
      </div>
      <div className="basis-1/4 flex justify-end">{rightSection}</div>
      {showWorkbookNameEditPanel && (
        <WorkbookNameEditPanel
          onDismiss={handleEditDismiss}
          name={name}
          description={description}
          setName={setName}
          setDescription={setDescription}
          workbook={workbook}
        />
      )}
    </div>
  );
};

export default SetUpSamples;
