type ColorType = {
  stroke: string;
  fill: string;
  used?: boolean;
};

type ColorGroupType = {
  [a: string]: ColorType;
};

export const waitEventGraphColors = {
  countGroup1: "#66c2a5",
  countGroup2: "#fc8d62",
  countGroup3: "#8da0cb",
  countGroup4: "#e78ac3",
  countGroup5: "#e5c494",
  countGroup6: "#ffd92f",
  countCPU: "#a6d854",
  countOther: "#f0f0f0",
};

export const connectionGraphColors = {
  countTotal: "#888888",
  countActive: "#c3d52a",
  countWaitingForLock: "#dc6254",
  countIdle: "#45ccd3",
  countIdleInTransaction: "#e3b514",
  countOther: "#ccc",
};

export const defaults: ColorGroupType = {
  red: {
    stroke: "#b64639",
    fill: "#dc6355",
  },
  orange: {
    stroke: "#b27a01",
    fill: "#e3b514",
  },
  yellowGreen: {
    stroke: "#9bac0c",
    fill: "#c3d52a",
  },
  lime: {
    stroke: "#60a121",
    fill: "#8cd347",
  },
  mediumSeaGreen: {
    stroke: "#1a9853",
    fill: "#47d386",
  },
  turquoise: {
    stroke: "#188a91",
    fill: "#47cbd3",
  },
  dodgerBlue: {
    stroke: "#1675ab",
    fill: "#39a9e9",
  },
  violet: {
    stroke: "#5d5bbb",
    fill: "#8f8dee",
  },
  orchid: {
    stroke: "#7d4fab",
    fill: "#da70d6",
  },
  violetRed: {
    stroke: "#b42b58",
    fill: "#ed789e",
  },
  grey: {
    stroke: "#888",
    fill: "#888",
  },
  babyBlue: {
    stroke: "#81c4f1",
    fill: "#81c4f1",
  },
  lightYellow: {
    stroke: "#f5e970",
    fill: "#f5e970",
  },
};

const fallbackColor: ColorType = {
  stroke: "#ccc",
  fill: "#ccc",
};

class Palette {
  colors: ColorGroupType;

  constructor(colors?: ColorGroupType) {
    this.colors = {};
    if (colors) {
      for (const color in colors) {
        this.colors[color] = Object.assign({}, colors[color]);
      }
    }
    for (const color in defaults) {
      this.colors[color] = Object.assign({}, defaults[color]);
    }
  }

  getStrokeAndFill(color: string | undefined | null): ColorType {
    return color
      ? color in defaults
        ? defaults[color]
        : { stroke: color, fill: color }
      : this.color();
  }

  private color(): ColorType {
    const unused = Object.values(this.colors).find((color) => !color.used);

    if (unused) {
      unused.used = true;
      return unused;
    } else {
      return fallbackColor;
    }
  }
}

export default Palette;
