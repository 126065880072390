import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";

import QUERY from "./Query.graphql";
import MUTATION from "./Mutation.graphql";
import {
  QueryForWorkbook,
  QueryForWorkbookVariables,
} from "./types/QueryForWorkbook";
import {
  CreateExplainWorkbook,
  CreateExplainWorkbookVariables,
} from "./types/CreateExplainWorkbook";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PageContent from "components/PageContent";
import PanelSection from "components/PanelSection";
import Callout from "components/Callout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import SQL from "components/SQL";
import { useRoutes } from "utils/routes";
import classNames from "classnames";
import { WorkbookCreationHeader } from "../SetUpSamples";

const ReviewQuery = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const queryId = searchParams.get("queryId");
  const { databaseId } = useParams();
  const [query, setQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [name, setName] = useState(
    queryId ? `Query #${queryId}` : "New workbook",
  );
  const [description, setDescription] = useState("");
  const [queryEdit, setQueryEdit] = useState(false);
  const navigate = useNavigate();
  const { databaseWorkbook } = useRoutes();

  const [createWorkbook] = useMutation<
    CreateExplainWorkbook,
    CreateExplainWorkbookVariables
  >(MUTATION);
  const [getQuery, { loading, error, data }] = useLazyQuery<
    QueryForWorkbook,
    QueryForWorkbookVariables
  >(QUERY);

  useEffect(() => {
    if (queryId) {
      getQuery({
        variables: { databaseId, queryId },
        onCompleted: (data) => {
          setQuery(data.getQueryForWorkbook.normalizedQuery);
        },
      });
    }
  }, [databaseId, queryId, getQuery]);

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const handleCheckQuery = () => {
    if (query.trim() === "") {
      setErrorMessage("Query text is required");
      return;
    }
    getQuery({
      variables: { databaseId, queryText: query },
      onCompleted: (data) => {
        setQueryEdit(false);
        if (data.getQueryForWorkbook.validQuery) {
          setErrorMessage("");
        } else {
          setErrorMessage(data.getQueryForWorkbook.errorMessage);
        }
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleCreate = () => {
    if (name === "") {
      setErrorMessage("Name is required");
      return;
    }
    if (query.trim() === "") {
      setErrorMessage("Query text is required");
      return;
    }
    if (!validQuery) {
      setErrorMessage("Query is not valid");
      return;
    }
    createWorkbook({
      variables: {
        databaseId: databaseId,
        name: name,
        queryText: query,
        description: description || null,
      },
      onCompleted: (data) => {
        navigate(
          databaseWorkbook(
            databaseId,
            data.createExplainWorkbook.explainWorkbookId,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  function handleUpdateQuery(newQuery: string) {
    setQueryEdit(true);
    setQuery(newQuery);
    setErrorMessage("");
  }

  const checkedQuery = data?.getQueryForWorkbook;
  const validQuery = checkedQuery?.validQuery && !queryEdit;
  const workbookTitle = (
    <WorkbookCreationHeader
      name={name}
      description={description}
      setName={setName}
      setDescription={setDescription}
      rightSection={
        validQuery ? (
          <button className="btn btn-success" onClick={handleCreate}>
            Set up Samples
          </button>
        ) : (
          <button className="btn btn-success" onClick={handleCheckQuery}>
            Check Query
          </button>
        )
      }
      focused="step1"
    />
  );

  const editButton = (
    <button onClick={() => setQueryEdit(true)}>
      <FontAwesomeIcon icon={faEdit} />
    </button>
  );

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${name}`}
      title={workbookTitle}
      pageCategory="query-tuning"
      pageName="workbooks"
      layout="sidebar"
    >
      <Panel title="Query Text" secondaryTitle={validQuery && editButton}>
        {validQuery && (
          <>
            {/* TODO: uncomment when a param name update is supported
          <PanelSection className="text-[#606060]">
            In order to process the query we normalized it and replaced numbered
            parameter values with human readable ones. Because we do not know if
            a variable contains the same content, we suffix the named values
            with consecutive numbers, for example param, param_2, param_3. You
            can merge these if you know that values are shared.
          </PanelSection>
          */}
          </>
        )}
        <PanelSection className="!border-t-0">
          <div className="mb-2 text-[#FF0000]">{errorMessage}</div>
          {validQuery ? (
            <QueryTextArea
              queryText={checkedQuery.normalizedQuery.replace(
                /\$[\d]+/g,
                (match) => {
                  const matchedParam = checkedQuery.parameters.find(
                    (param) => `$${param.ref}` === match,
                  );
                  return matchedParam ? `$${matchedParam.name}` : match;
                },
              )}
            />
          ) : (
            <textarea
              className="rounded border border-[#E8E8EE] p-2 text-[#606060] w-full font-query text-[13px]"
              rows={10}
              placeholder="Paste query text here..."
              value={query}
              onChange={(e) => handleUpdateQuery(e.target.value)}
            ></textarea>
          )}
        </PanelSection>
      </Panel>
      <div className="w-[320px]">
        {/* TODO: handle param name update */}
        {validQuery && (
          <>
            <Callout className="mb-4" thin>
              {checkedQuery.parameters.length} parameters detected
            </Callout>
            <div className="leading-7 font-medium my-2">Parameter Name</div>
            <div className="grid gap-2">
              {checkedQuery.parameters.map((val, idx) => {
                return (
                  <div key={`${val.name}-${idx}`}>
                    <input
                      className="bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5 w-[304px]"
                      type="text"
                      defaultValue={val.name}
                      disabled
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </PageContent>
  );
};

export const QueryTextArea = ({
  queryText,
  className,
}: {
  queryText: string;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "rounded border border-[#E8E8EE] p-2 text-[#606060]",
        className,
      )}
    >
      <SQL sql={queryText} />
    </div>
  );
};

export default ReviewQuery;
