import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { formatMs } from "utils/format";
import { InfoIcon } from "components/Icons";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PanelSection from "components/PanelSection";
import ExpandableSQL from "components/ExpandableSQL";

import {
  QuerySamples as QuerySamplesType,
  QuerySamplesVariables,
  QuerySamples_getQuerySamples as QuerySampleType,
  QuerySamples_getPostgresSettings as PostgresSettings,
} from "./types/QuerySamples";

import QUERY from "./Query.graphql";
import { useRoutes } from "utils/routes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import PanelParamSection from "components/ExplainPanel/PanelParamSection";

type SampleProps = {
  sample: QuerySampleType;
};

const QuerySample: React.FunctionComponent<SampleProps> = ({ sample }) => {
  const queryText = sample.queryText;

  return (
    <Panel
      title="Query Sample"
      key={sample.id}
      secondaryTitle={moment.unix(sample.occurredAt).format("lll z")}
    >
      <PanelSection>
        <ExpandableSQL sql={queryText} />
      </PanelSection>
      <PanelParamSection parameters={sample.parameters} />
      <PanelSection>
        <strong>Runtime:</strong> {formatMs(sample.runtimeMs)}
      </PanelSection>
    </Panel>
  );
};

type Props = {
  serverId: string;
  databaseId: string;
  queryId: string;
};

const QuerySamples: React.FunctionComponent<Props> = ({
  serverId,
  databaseId,
  queryId,
}) => {
  const { data, loading, error } = useQuery<
    QuerySamplesType,
    QuerySamplesVariables
  >(QUERY, {
    variables: {
      serverId,
      databaseId,
      queryId,
    },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const samples = data.getQuerySamples;

  if (samples.length === 0) {
    return (
      <NoSamples
        serverId={serverId}
        settings={data.getPostgresSettings}
        integratedLogInsights={data.getServerDetails.integratedLogInsights}
      />
    );
  }

  return (
    <>
      {samples.map(
        (sample: QuerySampleType): React.ReactNode => (
          <QuerySample key={sample.id} sample={sample} />
        ),
      )}
    </>
  );
};

const NoSamples: React.FunctionComponent<{
  serverId: string;
  integratedLogInsights: boolean;
  settings: PostgresSettings[];
}> = ({ serverId, integratedLogInsights, settings }) => {
  const { serverConfigSetting, serverSetupLogInsights } = useRoutes();
  const logMinDurationStmt = settings.find(
    (s) => s.name === "log_min_duration_statement",
  );
  const autoExplainLogMinDuration = settings.find(
    (s) => s.name === "auto_explain.log_min_duration",
  );
  return (
    <Panel title="No Samples Collected">
      <PanelSection>
        <p>
          <InfoIcon className="mr-1.5" />
          No query samples collected in the last 24 hours.
        </p>
        <div>
          Query samples are collected from your logfiles under the following
          conditions:
          <ul className="pt-2">
            <li>
              {integratedLogInsights ? (
                <>
                  <FontAwesomeIcon
                    className="text-[12px] text-[#93bf3b]"
                    icon={faCheckCircle}
                  />{" "}
                  Log Insights is integrated
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    className="text-[12px] text-[red]"
                    icon={faTimesCircle}
                  />{" "}
                  Log Insights must be integrated -{" "}
                  <Link to={serverSetupLogInsights(serverId)}>
                    Setup instructions
                  </Link>
                </>
              )}
            </li>
            <li>
              The query execution time exceeds either
              <ul className="!mb-0">
                <li>
                  <Link
                    to={serverConfigSetting(
                      serverId,
                      "log_min_duration_statement",
                    )}
                  >
                    log_min_duration_statement
                  </Link>{" "}
                  (currently{" "}
                  {logMinDurationStmt ? (
                    <code>{logMinDurationStmt.resetValuePretty}</code>
                  ) : (
                    "unset"
                  )}
                  )
                </li>
                <li>
                  <Link
                    to={serverConfigSetting(
                      serverId,
                      "auto_explain.log_min_duration",
                    )}
                  >
                    auto_explain.log_min_duration
                  </Link>
                  , if using{" "}
                  <a
                    target="_blank"
                    rel="noopener"
                    href="https://pganalyze.com/docs/explain/setup/auto_explain"
                  >
                    auto_explain
                  </a>{" "}
                  (currently{" "}
                  {autoExplainLogMinDuration ? (
                    <code>{autoExplainLogMinDuration.resetValuePretty}</code>
                  ) : (
                    "unset"
                  )}
                  )
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </PanelSection>
    </Panel>
  );
};

export default QuerySamples;
