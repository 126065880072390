import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import QUERY from "../ExplainWorkbook/Query.graphql";
import {
  ExplainWorkbookDetails,
  ExplainWorkbookDetailsVariables,
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
} from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import CHECK_QUERY from "../ReviewQuery/Query.graphql";
import {
  QueryForWorkbook,
  QueryForWorkbookVariables,
} from "../ReviewQuery/types/QueryForWorkbook";
import MUTATION from "./Mutation.graphql";
import {
  CreateExplainQuery,
  CreateExplainQueryVariables,
} from "./types/CreateExplainQuery";
import Loading from "components/Loading";
import Panel from "components/Panel";
import PageContent from "components/PageContent";
import PanelSection from "components/PanelSection";
import Callout from "components/Callout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle1, faCircle2, faEdit } from "@fortawesome/pro-solid-svg-icons";
import ModalContainer from "components/ModalContainer";
import Tip from "components/Tip";
import { useRoutes } from "utils/routes";
import { QueryTextArea } from "../ReviewQuery";

const RewriteQuery = () => {
  const { workbookId } = useParams();

  const { loading, error, data } = useQuery<
    ExplainWorkbookDetails,
    ExplainWorkbookDetailsVariables
  >(QUERY, {
    variables: { workbookId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  return <RewriteQueryContent workbook={data.getExplainWorkbookDetails} />;
};

type PlannerSettings = {
  [key: string]: boolean;
};

const RewriteQueryContent = ({
  workbook,
}: {
  workbook: ExplainWorkbookType;
}) => {
  const baselineQuery = workbook.baselineQuery;
  const navigate = useNavigate();
  const { databaseWorkbookVariantRun } = useRoutes();

  const { databaseId } = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [queryEdit, setQueryEdit] = useState(false);
  const [query, setQuery] = useState(baselineQuery.queryTextWithAlias);
  const [name, setName] = useState("");
  const [plannerSettings, setPlannerSettings] = useState<PlannerSettings>({
    enable_hashjoin: true,
    enable_mergejoin: true,
    enable_nestloop: true,
    enable_indexscan: true,
    enable_seqscan: true,
    enable_bitmapscan: true,
  });

  // Query for checking if the query is valid
  const [getQuery, { loading, error, data }] = useLazyQuery<
    QueryForWorkbook,
    QueryForWorkbookVariables
  >(CHECK_QUERY);
  // Mutation for creating explain query (new variant)
  const [createExplainQuery] = useMutation<
    CreateExplainQuery,
    CreateExplainQueryVariables
  >(MUTATION);

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const handleCheckQuery = () => {
    if (query.trim() === "") {
      setErrorMessage("Query text is required");
      return;
    }
    getQuery({
      variables: { databaseId, queryText: query, baselineId: baselineQuery.id },
      onCompleted: (data) => {
        setQueryEdit(false);
        if (data.getQueryForWorkbook.validQuery) {
          setErrorMessage("");
        } else {
          setErrorMessage(data.getQueryForWorkbook.errorMessage);
        }
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleRunExplain = () => {
    if (!validQuery) {
      setErrorMessage("Query is not valid");
      return;
    }
    // TODO: pass planner settings
    createExplainQuery({
      variables: {
        databaseId: workbook.databaseId,
        workbookId: workbook.id,
        explainQueryName: name,
        baselineExplainQueryId: baselineQuery.id,
        queryText: query,
      },
      refetchQueries: [
        {
          query: WORKBOOK_DETAIL_QUERY,
          variables: {
            workbookId: workbook.id,
            databaseId: workbook.databaseId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        navigate(
          databaseWorkbookVariantRun(
            workbook.databaseId,
            workbook.id,
            data.createExplainQuery.id,
          ),
        );
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  function handleUpdateQuery(newQuery: string) {
    setQueryEdit(true);
    setQuery(newQuery);
    setErrorMessage("");
  }

  const checkedQuery = data?.getQueryForWorkbook;
  const validQuery = checkedQuery?.validQuery && !queryEdit;
  const workbookTitle = (
    <VariantCreationHeader
      name={name}
      setName={setName}
      rightSection={
        validQuery ? (
          <button className="btn btn-success" onClick={handleRunExplain}>
            Run EXPLAIN...
          </button>
        ) : (
          <button className="btn btn-success" onClick={handleCheckQuery}>
            Check Query
          </button>
        )
      }
      focused="step1"
    />
  );

  const editButton = (
    <button onClick={() => setQueryEdit(true)}>
      <FontAwesomeIcon icon={faEdit} />
    </button>
  );

  const handleSettingChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const settingName = e.currentTarget.dataset.setting;
    const newSettings = {
      ...plannerSettings,
      [settingName]: e.currentTarget.checked,
    };
    setPlannerSettings(newSettings);
  };

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      title={workbookTitle}
      pageCategory="query-tuning"
      pageName="workbooks"
      layout="sidebar"
    >
      <Panel title="Rewrite Query" secondaryTitle={validQuery && editButton}>
        <PanelSection className="!border-t-0">
          <div className="mb-2 text-[#FF0000]">{errorMessage}</div>
          {validQuery ? (
            <QueryTextArea
              queryText={checkedQuery.normalizedQuery.replace(
                /\$[\d]+/g,
                (match) => {
                  const matchedParam = checkedQuery.parameters.find(
                    (param) => `$${param.ref}` === match,
                  );
                  return matchedParam ? `$${matchedParam.name}` : match;
                },
              )}
            />
          ) : (
            <textarea
              className="rounded border border-[#E8E8EE] p-2 text-[#606060] w-full font-query text-[13px]"
              rows={10}
              value={query}
              onChange={(e) => handleUpdateQuery(e.target.value)}
            />
          )}
        </PanelSection>
      </Panel>
      <div className="w-[320px]">
        <Callout className="!py-2 mb-4">
          Customize planner behavior by changing the settings. Settings are only
          applied to the current session.
        </Callout>
        <div className="leading-7 font-medium my-2">JOIN-Related Settings</div>
        <div className="grid gap-3">
          <div className="flex">
            <div className="grow">
              <span className="font-mono">enable_hashjoin</span>{" "}
              <Tip content="Enables or disables the query planner's use of hash-join plan types." />
            </div>
            <div className="justify-self-end">
              <input
                data-setting="enable_hashjoin"
                type="checkbox"
                checked={plannerSettings["enable_hashjoin"]}
                onChange={handleSettingChecked}
              />
            </div>
          </div>
          <div className="flex">
            <div className="grow">
              <span className="font-mono">enable_mergejoin</span>{" "}
              <Tip content="Enables or disables the query planner's use of merge-join plan types." />
            </div>
            <div className="justify-self-end">
              <input
                data-setting="enable_mergejoin"
                type="checkbox"
                checked={plannerSettings["enable_mergejoin"]}
                onChange={handleSettingChecked}
              />
            </div>
          </div>
          <div className="flex">
            <div className="grow">
              <span className="font-mono">enable_nestloop</span>{" "}
              <Tip content="Enables or disables the query planner's use of nested-loop join plans. It is impossible to suppress nested-loop joins entirely, but turning this variable off discourages the planner from using one if there are other methods available." />
            </div>
            <div className="justify-self-end">
              <input
                data-setting="enable_nestloop"
                type="checkbox"
                checked={plannerSettings["enable_nestloop"]}
                onChange={handleSettingChecked}
              />
            </div>
          </div>
        </div>
        <div className="leading-7 font-medium my-2">Scan-Related Settings</div>
        <div className="grid gap-3">
          <div className="flex">
            <div className="grow">
              <span className="font-mono">enable_indexscan</span>{" "}
              <Tip content="Enables or disables the query planner's use of index-scan and index-only-scan plan types." />
            </div>
            <div className="justify-self-end">
              <input
                data-setting="enable_indexscan"
                type="checkbox"
                checked={plannerSettings["enable_indexscan"]}
                onChange={handleSettingChecked}
              />
            </div>
          </div>
          <div className="flex">
            <div className="grow">
              <span className="font-mono">enable_seqscan</span>{" "}
              <Tip content="Enables or disables the query planner's use of sequential scan plan types. It is impossible to suppress sequential scans entirely, but turning this variable off discourages the planner from using one if there are other methods available." />
            </div>
            <div className="justify-self-end">
              <input
                data-setting="enable_seqscan"
                type="checkbox"
                checked={plannerSettings["enable_seqscan"]}
                onChange={handleSettingChecked}
              />
            </div>
          </div>
          <div className="flex">
            <div className="grow">
              <span className="font-mono">enable_bitmapscan</span>{" "}
              <Tip content="Enables or disables the query planner's use of bitmap-scan plan types." />
            </div>
            <div className="justify-self-end">
              <input
                data-setting="enable_bitmapscan"
                type="checkbox"
                checked={plannerSettings["enable_bitmapscan"]}
                onChange={handleSettingChecked}
              />
            </div>
          </div>
        </div>
      </div>
    </PageContent>
  );
};

const VariantNameEditPanel = ({
  onDismiss,
  name,
  setName,
}: {
  onDismiss: () => void;
  name: string;
  setName: (name: string) => void;
}) => {
  return (
    <ModalContainer title="Edit Variant" onClose={onDismiss} layout="centered">
      <div className="grid grid-cols-2 mt-4">
        <div className="mb-1 font-medium col-span-2">Name</div>
        <input
          className="col-span-2 bg-white rounded border border-gray-300 box-content h-5 leading-5 px-2 py-1.5"
          type="text"
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="Name of variant"
        />
      </div>
      <div className="mt-4">
        <button className="btn btn-success" onClick={onDismiss}>
          Update
        </button>
      </div>
    </ModalContainer>
  );
};

export const VariantCreationHeader = ({
  name,
  setName,
  rightSection,
  focused,
  disableNameEdit,
}: {
  name: string;
  setName?: (name: string) => void;
  rightSection?: React.ReactNode;
  focused: "step1" | "step2";
  disableNameEdit?: boolean;
}) => {
  const [showVariantNameEditPanel, setShowVariantNameEditPanel] =
    useState(false);

  const step1Class = focused === "step1" ? "text-[#29426D]" : "text-[#979797]";
  const step2Class = focused === "step2" ? "text-[#29426D]" : "text-[#979797]";

  function handleEditShow() {
    setShowVariantNameEditPanel(true);
  }
  function handleEditDismiss() {
    setShowVariantNameEditPanel(false);
  }

  return (
    <div className="flex flex-row justify-between items-center">
      <div className="basis-1/4">
        <h2
          className="text-[22px] text-[#606060] m-0 py-[9px] font-medium overflow-visible leading-[26px]"
          onClick={disableNameEdit ? () => {} : handleEditShow}
        >
          {name || "Untitled Variant"}
        </h2>
      </div>
      <div className="basis-1/2 align-middle text-[14px] leading-5 flex items-center justify-center gap-5">
        <div className={step1Class}>
          <FontAwesomeIcon icon={faCircle1} /> Rewrite Query and Edit Planner
          Settings
        </div>
        <div className={step2Class}>
          <FontAwesomeIcon icon={faCircle2} /> Run EXPLAIN
        </div>
      </div>
      <div className="basis-1/4 flex justify-end">{rightSection}</div>
      {showVariantNameEditPanel && (
        <VariantNameEditPanel
          onDismiss={handleEditDismiss}
          name={name}
          setName={setName}
        />
      )}
    </div>
  );
};

export default RewriteQuery;
